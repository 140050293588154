import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { Notification } from "./notification";
import { NotificationOptions } from "./notification-options";

let NEXT_ID = 0;

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private currentNotifications: Notification[] = [];
  private notificationsSubject: BehaviorSubject<Notification[]> =
    new BehaviorSubject(this.currentNotifications);

  constructor(router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(this.clearPageNotifications);
  }

  public getNotifications(): Observable<Notification[]> {
    return this.notificationsSubject;
  }

  public notify(options: NotificationOptions) {
    const id = NEXT_ID++;
    const notification = {
      id,
      options,
      onDismiss: this.generateDismissNotification(id, options.onDismiss),
    };
    this.currentNotifications = this.currentNotifications.concat(notification);
    this.notificationsSubject.next(this.currentNotifications);
    return id;
  }

  public dismissNotification(id: number) {
    const notification = this.currentNotifications.find(
      (currentNotification) => currentNotification.id === id,
    );
    if (notification != null) {
      notification.onDismiss();
    }
  }

  private clearPageNotifications = () => {
    this.currentNotifications = this.currentNotifications.filter(
      (notification) => !notification.options.forPage,
    );
    this.notificationsSubject.next(this.currentNotifications);
  };

  private generateDismissNotification =
    (id: number, wrappedOnDismiss?: () => void) => () => {
      this.currentNotifications = this.currentNotifications.filter(
        (notification) => notification.id !== id,
      );
      this.notificationsSubject.next(this.currentNotifications);
      if (wrappedOnDismiss != null) {
        wrappedOnDismiss();
      }
    };
}
