import { Component, OnInit } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  NavigationError,
  Router,
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { PageFocusService, StorageService } from "@sumday/common";
import { first, map } from "rxjs/operators";

import { environment } from "../environments/environment";

import { NotificationsService } from "./shared/notifications/notifications.service";
import { AuthService } from "./shell/auth/auth.service";
import { TrackingService } from "./shell/tracking/tracking.service";

const TIME_OF_LAST_INSTALL_PROMPT = "TIME_OF_LAST_INSTALL_PROMPT";
const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Access-Control-Allow-Origin", "*");
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "SAM-Frontend";

  deferredPrompt: any;
  apiAccessToken: string | undefined = undefined;
  jsonDataResult: any;
  businesscontinuitymsg: string;
  private previousSnapshot: ActivatedRouteSnapshot;

  constructor(
    private readonly storage: StorageService,
    private router: Router,
    private pageFocusService: PageFocusService,
    trackingService: TrackingService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private httpService: HttpClient,
  ) {
    trackingService.startTracking();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const currentSnapshot = this.router.routerState.snapshot.root;
        if (
          !AppComponent.areSnapshotsEqual(
            this.previousSnapshot,
            currentSnapshot,
          )
        ) {
          this.pageFocusService.scrollToTop();
          this.previousSnapshot = currentSnapshot;
        }
        // we set API access token to have the access to it from E2E test
        if (!environment.production) {
          this.authService.tokenSet$
            .pipe(
              first(),
              map((tokenSet) => {
                return tokenSet
                  ? `Bearer ${this.authService.getTokenForApi(tokenSet)}`
                  : undefined;
              }),
            )
            .subscribe((v) => {
              this.apiAccessToken = v;
            });
        }
      } else if (event instanceof NavigationError) {
        router.navigate(["/error"]);
      }
    });

    const timeOfLastInstallPrompt = this.storage.get<number>(
      TIME_OF_LAST_INSTALL_PROMPT,
    );

    if (
      // HACK, ToDo: vic: temporary removed by Katherine's request
      false &&
      environment.production &&
      (timeOfLastInstallPrompt == null ||
        timeOfLastInstallPrompt <
          Date.now() - environment.timeBetweenInstallPrompts)
    ) {
      window.addEventListener("beforeinstallprompt", (e: any) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        this.deferredPrompt = e;
        this.notificationsService.notify({
          notificationType: "confirmation",
          type: "cta",
          confirmText: "Install",
          onConfirm: () => this.onInstall(),
          cancelText: "No thanks",
          onCancel: () => this.onDismiss(),
          content:
            "Shareholder Account Manager is available as an app. Would you like to install it?",
        });
      });
    }
  }

  static areSnapshotsEqual(
    previous: ActivatedRouteSnapshot | null,
    current: ActivatedRouteSnapshot | null,
  ): boolean {
    if (previous == null || current == null) {
      return previous === current;
    }

    const isCurrentPartEqual =
      previous.routeConfig != null && current.routeConfig != null
        ? previous.routeConfig.path === current.routeConfig.path
        : previous.routeConfig === current.routeConfig;

    return (
      isCurrentPartEqual &&
      AppComponent.areSnapshotsEqual(previous.firstChild, current.firstChild)
    );
  }

  onInstall() {
    this.deferredPrompt.prompt();
  }
  ngOnInit() {
    this.httpService.get("/businesscontinuitymsg/", { headers }).subscribe(
      (data) => {
        this.jsonDataResult = data;
        this.businesscontinuitymsg = this.jsonDataResult.page.text_s;
      },
      (err: HttpErrorResponse) => {
        console.error(err);
      },
    );
  }
  onDismiss() {
    this.storage.set(TIME_OF_LAST_INSTALL_PROMPT, Date.now());
  }
}
