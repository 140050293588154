<ng-container *ngFor="let notification of notifications; let i = index">
  <app-notification
    *ngIf="notification.options.notificationType !== 'confirmation'"
    [notification]="notification"
    [attr.data-qa]="'notif__' + i"
  ></app-notification>
  <app-confirmation
    *ngIf="notification.options.notificationType === 'confirmation'"
    [confirmation]="$any(notification)"
    [attr.data-qa]="'notif_confirmation__' + i"
  ></app-confirmation>
</ng-container>
