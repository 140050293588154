import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Notification } from "../../shared/notifications/notification";
import { NotificationsService } from "../../shared/notifications/notifications.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();

  public notifications: Notification[];

  constructor(
    private notificationsService: NotificationsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.notificationsService
      .getNotifications()
      .pipe(takeUntil(this.destroyed))
      .subscribe((notifications) => {
        this.notifications = notifications;

        // Some notifications are sent from services outside the change detector.
        // This is making sure we display all notifications, regardless of how they were created.
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
