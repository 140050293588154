import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { MenuItem } from "./header.service";

@Component({
  selector: "app-header-link",
  templateUrl: "./header-link.component.html",
  styleUrls: ["./header-link.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinkComponent implements OnInit, OnDestroy {
  @Input() link: MenuItem;
  @Input() isMobile = false;
  @Input() isChild = false;

  currentLocation = "";
  shouldHideChildMenu = true;
  private destroyed = new Subject<void>();

  constructor(private router: Router) {
    this.currentLocation = window.location.href;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed),
      )
      .subscribe((_) => {
        this.currentLocation = window.location.href;
      });
  }

  toggleChildMenu($event: Event) {
    $event.stopPropagation();
    this.shouldHideChildMenu = !this.shouldHideChildMenu;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
