import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface MenuItem {
  label: string;
  url?: string;
  state?: string;
  urlExact?: boolean;
  children?: MenuItem[];
  childrenContainerClass?: string;
}

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  private menusSubject = new BehaviorSubject<MenuItem[]>([]);
  private servicemenusSubject = new BehaviorSubject<MenuItem[]>([]);

  private accountIdSubject = new BehaviorSubject<{
    accountId: string | null;
    force: boolean;
  }>({
    accountId: null,
    force: false,
  });

  get menus$() {
    return this.menusSubject.asObservable();
  }
  get serviceformsmenus$() {
    return this.servicemenusSubject.asObservable();
  }
  get accountId$() {
    return this.accountIdSubject.asObservable();
  }

  constructor() {}

  setupContextualMenu(menus: MenuItem[]) {
    this.menusSubject.next(menus);
  }

  clear() {
    this.menusSubject.next([]);
    this.servicemenusSubject.next([]);
    this.accountIdSubject.next({ accountId: null, force: false });
  }

  setAccountId(id: string | null, force = false) {
    this.accountIdSubject.next({ accountId: id, force });
  }
  setupServiceFormsMenu(menus: MenuItem[]) {
    this.servicemenusSubject.next(menus);
  }
}
