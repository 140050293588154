import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  static getStatusCodeClass(statusCode: number): number {
    // HTTP 4xx becomes 4, HTTP 5xx becomes 5, etc
    const statusCodeClass = Math.trunc(statusCode / 100);

    return statusCodeClass;
  }

  getHttpDataRecord<T>(url: string, piiKey?: string) {
    let dataRecord$: Observable<{ data: T }>;
    if (piiKey != null) {
      dataRecord$ = this.httpClient.get<{ data: T }>(url, {
        headers: new HttpHeaders({
          ["Sumday-PII-Key"]: piiKey,
        }),
      });
    } else {
      dataRecord$ = this.httpClient.get<{ data: T }>(url);
    }
    return dataRecord$.pipe(
      filter((response) => response != null),
      map((response) => response.data),
      take(1),
    );
  }
}
