<sumday-header
  (mobileNavIsOpen)="handleMobileMenuOpening($event)"
  class="screen-only"
>
  <div logo>
    <a [attr.data-qa]="'app-logo'" class="logo-link" routerLink="/home">
      <img
        class="primerica-logo"
        src="/assets/primerica-logo-small.jpg"
        alt="Primerica"
      />
    </a>
  </div>
  <div additionalHeaderContent class="nav-container">
    <app-back-button></app-back-button>
  </div>

  <ul mainMenu>
    <ng-container>
      <li *ngIf="(researchMenuItem.children?.length || 0) > 0">
        <app-header-link
          [link]="researchMenuItem"
          tabindex="0"
        ></app-header-link>
      </li>
      <li *ngIf="(serviceFormsMenuItem.children?.length || 0) > 0">
        <app-header-link
          [link]="serviceFormsMenuItem"
          tabindex="0"
        ></app-header-link>
      </li>
    </ng-container>
  </ul>

  <ul mobileMenu>
    <li *ngIf="(researchMenuItem.children?.length || 0) > 0">
      <app-header-link
        [link]="researchMenuItem"
        [isMobile]="true"
        tabindex="0"
      ></app-header-link>
    </li>
    <li *ngIf="(serviceFormsMenuItem.children?.length || 0) > 0">
      <app-header-link
        [link]="serviceFormsMenuItem"
        [isMobile]="true"
        tabindex="0"
      ></app-header-link>
    </li>
  </ul>
</sumday-header>

<ng-template #contextualMenus>
  <li *ngFor="let link of headerService.menus$ | async">
    <app-header-link [link]="link"></app-header-link>
  </li>
</ng-template>
