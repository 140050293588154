import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent {
  @Input() pageTitle: string;
  @Input() subTitle?: string;
  @Input() hideTitle: boolean;
  @Input() extraTitleClasses = "";
  @Input() extraSubtitleClasses = "";
  @Input() pageDesktopSize = 8;
}
