import { SelectItem } from "@sumday/common";

let mapper: Record<string, string>;

export const fundFamilyMappings = {
  setMapper: (fundFamilyMapper: Record<string, string>) => {
    mapper = fundFamilyMapper;
  },

  mapper: (): Record<string, string> => {
    return mapper;
  },

  mapName: (value: string | null | undefined): string => {
    const fundFamilyMapper = fundFamilyMappings.mapper();
    if (!fundFamilyMapper) {
      console.error("Fund Family mapper is not initialized");
    }
    const strValue = value?.toString().toLowerCase();
    return !strValue ? "" : fundFamilyMapper[strValue] ?? "";
  },

  getItems: (): SelectItem[] => {
    return Object.keys(mapper)
      .map((k) => {
        return { value: k, text: mapper[k] };
      })
      .sort((a, b) => (a.text > b.text ? 1 : -1));
  },
};
