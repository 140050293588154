import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "accounting", pure: true })
export class AccountingPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value == null || typeof value !== "string") {
      return value;
    }

    if (value.charAt(0) === "-") {
      return `(${value.substr(1)})`;
    } else {
      return value;
    }
  }
}
