import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { ErrorResponseWrapper } from "@sumday/common";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { SupportCode } from "../../models/ApiResponse";
import { HttpService } from "../../shared/httpService";

export const catchApiError = (
  router: Router,
  returnLink?: string,
  skipNotFoundError = false,
  notFoundRedirectRoute: string | null = null,
) =>
  catchError<any, any>((error) => {
    if (error.status === 404 && skipNotFoundError) {
      if (notFoundRedirectRoute) {
        router.navigate([notFoundRedirectRoute]);
      }
      return of({});
    }

    const errorDescription = extractErrorMessage(error);
    router.navigate(["/error"], { state: { returnLink, errorDescription } });
    return of();
  });

export const CONTACT_CUSTOMER_SERVICE_MESSAGE =
  "Please contact customer support for assistance.";
export const APP_DROPPED_THE_BALL_MESSAGE = "Our app dropped the ball.";
export const NO_DATA_FOUND_MESSAGE = "There is no data requested.";
export const SERVER_OUT_OR_OFFLINE_MESSAGE =
  "Our server is down or you appear to be out of internet. Please try again later.";
export const OFFLINE_MESSAGE =
  "You appear to be out of internet. Come back when you find some more.";

export function extractErrorMessage(err: any): string {
  if (err instanceof HttpErrorResponse) {
    const supportCode: SupportCode | undefined =
      err.error && err.error.supportCode;
    if (supportCode) {
      return combineErrorMessages(
        `Error ${supportCode.code}: ${supportCode.message}`,
        CONTACT_CUSTOMER_SERVICE_MESSAGE,
      );
    }

    const errors: Api.ApiError[] | undefined = err.error && err.error.errors;
    if (errors && Array.isArray(errors) && errors.length > 0) {
      return combineErrorMessages(
        errors[0].description || APP_DROPPED_THE_BALL_MESSAGE,
        CONTACT_CUSTOMER_SERVICE_MESSAGE,
      );
    }

    if (err.status === 504) {
      return SERVER_OUT_OR_OFFLINE_MESSAGE;
    }
    if (err.status === 404) {
      return NO_DATA_FOUND_MESSAGE;
    }
    if (err.status === 0) {
      return OFFLINE_MESSAGE;
    }
  }

  return combineErrorMessages(
    APP_DROPPED_THE_BALL_MESSAGE,
    CONTACT_CUSTOMER_SERVICE_MESSAGE,
  );
}

export function combineErrorMessages(...messages: string[]): string {
  const toCombine = messages?.map((msg, index) => {
    let transformed = msg.trim();

    if (transformed.endsWith(".")) {
      transformed = msg.substr(0, msg.length - 1);
    }

    if (index === messages.length - 1) {
      transformed = `${transformed}.`;
    }

    return transformed;
  });

  const finalMsg = toCombine.join(". ");
  return finalMsg;
}

export const updateFieldNameInValidationErrors = (
  error: ErrorResponseWrapper,
  updateFieldName: (fieldName: string) => string,
) => ({
  ...error,
  validationErrors:
    error?.validationErrors &&
    Object.entries(error?.validationErrors).reduce(
      (acc, [fieldName, errors]) => ({
        ...acc,
        [updateFieldName(fieldName)]: errors,
      }),
      {},
    ),
});

export const extractErrorResponseWrapper = (
  error: any,
): ErrorResponseWrapper | null => {
  if (typeof error === "object" && error?.validationErrors) {
    return error as ErrorResponseWrapper;
  }
  try {
    const result = JSON.parse(error);
    if (typeof result === "object") {
      return result as ErrorResponseWrapper;
    }
  } catch {
    return null;
  }
  return null;
};

export function extractValidationMessage(
  errorResponse: any,
): string | undefined {
  if (errorResponse instanceof HttpErrorResponse) {
    const statusCodeClass = HttpService.getStatusCodeClass(
      errorResponse.status,
    );

    if (statusCodeClass === 4 && errorResponse?.error) {
      const { validationErrors } =
        errorResponse?.error as Api.ApiValidationErrorResponse;
      const generalValidationErrors = validationErrors && validationErrors[""];
      if (
        generalValidationErrors &&
        generalValidationErrors?.length > 0 &&
        generalValidationErrors[0]?.description
      ) {
        const message = generalValidationErrors[0]?.description;
        return message;
      }
    }
  }

  return undefined;
}
