<sumday-notification
  [type]="$any(notification.options.type)"
  [icon]="$any(notification.options.icon)"
  [link]="$any(link)"
  [showDismissButton]="$any(showDismissButton)"
  (dismissEvent)="notification.onDismiss()"
  [attr.data-qa]="notification.options.qaId"
>
  {{ notification.options.content }}
  <ng-content></ng-content>
  <template #componentContent></template>
</sumday-notification>
