import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from "@angular/material/core";
import { RouterModule } from "@angular/router";
import { SumdayCommonModule } from "@sumday/common";
import { SumdayComponentModule } from "@sumday/components";

import { LoggedInUserModule } from "../shell/logged-in-user/logged-in-user.module";

import { AccordionComponent } from "./components/accordion/accordion.component";
import { AddressComponent } from "./components/address/address.component";
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { ChecklistComponent } from "./components/checklist/checklist.component";
import { ContextMenuComponent } from "./components/context-menu/context-menu.component";
import { DrawerComponent } from "./components/drawer/drawer.component";
import { AccordionListComponent } from "./components/dynamic-components/accordion-list/accordion-list.component";
import { CardListComponent } from "./components/dynamic-components/card-list/card-list.component";
import { ActionCellRendererComponent } from "./components/dynamic-components/cell-renderers/action-cell-renderer.component";
import { CheckButtonRendererComponent } from "./components/dynamic-components/cell-renderers/check-button-renderer";
import { CheckTitleRendererComponent } from "./components/dynamic-components/cell-renderers/check-title-renderer.component";
import { DropdownRendererComponent } from "./components/dynamic-components/cell-renderers/dropdown-renderer.component";
import { EditableCellRendererComponent } from "./components/dynamic-components/cell-renderers/editable-cell-renderer";
import { ExternalLinkCellRendererComponent } from "./components/dynamic-components/cell-renderers/external-link-cell-renderer";
import { MaskedCellRendererComponent } from "./components/dynamic-components/cell-renderers/masked-cell-renderer.component";
import { MultilineCellRendererComponent } from "./components/dynamic-components/cell-renderers/multi-line-cell-renderer.component";
import { PriceChangeCellRendererComponent } from "./components/dynamic-components/cell-renderers/price-change-cell-renderer.component";
import { RadioButtonCellRendererComponent } from "./components/dynamic-components/cell-renderers/radio-button-cell-renderer.component";
import { RadioTitleHeaderRendererComponent } from "./components/dynamic-components/cell-renderers/radio-title-header-renderer.component";
import { RouterLinkCellRendererComponent } from "./components/dynamic-components/cell-renderers/router-link-cell-renderer";
import { SecureDownloadCellRendererComponent } from "./components/dynamic-components/cell-renderers/secure-download-cell-renderer.component";
import { TextCellRendererComponent } from "./components/dynamic-components/cell-renderers/text-cell-renderer.component";
import { ToggleIconCellRendererComponent } from "./components/dynamic-components/cell-renderers/toggle-icon-cell-renderer.component";
import { TransformCellRendererComponent } from "./components/dynamic-components/cell-renderers/transform-cell-renderer.component";
import { ComposableTableFooterLabelRendererComponent } from "./components/dynamic-components/composable-table/composable-table-footer-label-renderer.component";
import { ComposableTableFooterComponent } from "./components/dynamic-components/composable-table/composable-table-footer.component";
import { ComposableTableHeaderComponent } from "./components/dynamic-components/composable-table/composable-table-header.component";
import { ComposableTableRowsComponent } from "./components/dynamic-components/composable-table/composable-table-rows.component";
import { DynamicCellDirective } from "./components/dynamic-components/dynamic-cell.directive";
import { ResultsTableComponent } from "./components/dynamic-components/results-table/results-table.component";
import { EnvelopeComponent } from "./components/envelope/envelope.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { FilterDescriptionComponent } from "./components/filter-select/filter-description/filter-description.component";
import { FilterSelectComponent } from "./components/filter-select/filter-select.component";
// import { CurrentInvestmentsComponent } from './components/financial-info/current-investments.component';
// import { FinancialProfileComponent } from './components/financial-info/financial-profile.component';
// import { LiquidityComponent } from './components/financial-info/liquidity.component';
// import { RpqComponent } from './components/financial-info/rpq.component';
import { FormErrorsComponent } from "./components/form-errors/form-errors.component";
import { FormFooterComponent } from "./components/form-footer/form-footer.component";
import { FundSelectionDropdownComponent } from "./components/fund-selector/fund-selection-dropdown.component";
import { FundSelectorDialogComponent } from "./components/fund-selector/fund-selector-dialog.component";
import { FundSelectorComponent } from "./components/fund-selector/fund-selector.component";
import { InstructionalMessageComponent } from "./components/instructional-message/instructional-message.component";
// import { ErisaDisclosureComponent } from './components/investment-income/erisa-disclosure/erisa-disclosure.component';
// import { IncomeTypeWarningComponent } from './components/investment-income/income-type.warning.component';
// import { MutualDisclosureComponent } from './components/investment-income/mutual-disclosure/mutual-disclosure.component';
// import { PensionDisclosureComponent } from './components/investment-income/pension-disclosure/pension-disclosure.component';
// import { SupplementalDisclosureComponent } from './components/investment-income/supplemental-disclosure/supplemental-disclosure.component';
import { LoadingIndicatorComponent } from "./components/loading-indicator/loading-indicator.component";
import { MaskedValueComponent } from "./components/masked-value/masked-value.component";
import { MultiSummaryBlockComponent } from "./components/multi-summary-block/multi-summary-block.component";
import { PageComponent } from "./components/page/page.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { RadioGroupComponent } from "./components/radio-group/radio-group.component";
import { ReviewBlockComponent } from "./components/review-block/review-block.component";
import { SearchChipsComponent } from "./components/search-chips/search-chips.component";
import { SuccessDialogComponent } from "./components/success-dialog/success-dialog.component";
import { SvgComponent } from "./components/svg/svg-component";
import { SwitchComponent } from "./components/switch/switch.component";
import { TileComponent } from "./components/tile.component";
import { AccordionTableDirective } from "./directives/accordion-table.directive";
import { ExternalLinkWarnDirective } from "./directives/external-link-warn.directive";
import { IfBreakpointDirective } from "./directives/if-breakpoint.directive";
import { IfWritingAgentDirective } from "./directives/if-writing-agent.directive";
import { InputAutoFocusDirective } from "./directives/input.autofocus.directive";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { SecureDownloadDirective } from "./directives/secure-download.directive";
import { AccountingPipe } from "./pipes/accounting";
import { AccountingCurrencyPipe } from "./pipes/accounting-currency";
import { AccountingSharesPipe } from "./pipes/accounting-shares";
import { DivideByPipe } from "./pipes/divide-by";
import { FormatFileSizePipe } from "./pipes/file-size";
import { FirstOfMonthPipe } from "./pipes/firstOfMonth";
import { LastFourTaxIdPipe } from "./pipes/last-four-tax-id";
import { NumericRangePipe } from "./pipes/numeric-range.pipe";
import { SecureUrlPipe } from "./pipes/secure-url.pipe";
import { YesNoPipe } from "./pipes/yes-no.pipe";

const materialModules = [MatAutocompleteModule];
const pipes = [
  AccountingCurrencyPipe,
  AccountingPipe,
  AccountingSharesPipe,
  DivideByPipe,
  FirstOfMonthPipe,
  LastFourTaxIdPipe,
  NumericRangePipe,
  YesNoPipe,
  SecureUrlPipe,
  FormatFileSizePipe,
];

const cellRenderers = [
  ActionCellRendererComponent,
  SecureDownloadCellRendererComponent,
  RouterLinkCellRendererComponent,
  ExternalLinkCellRendererComponent,
  TextCellRendererComponent,
  TextCellRendererComponent,
  TransformCellRendererComponent,
  EditableCellRendererComponent,
  MaskedCellRendererComponent,
  ToggleIconCellRendererComponent,
  RadioButtonCellRendererComponent,
  PriceChangeCellRendererComponent,
  MultilineCellRendererComponent,
];

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [
    SwitchComponent,
    AccordionComponent,
    AccordionListComponent,
    AccordionTableDirective,
    CardListComponent,
    ChecklistComponent,
    ContextMenuComponent,
    DrawerComponent,
    ExternalLinkWarnDirective,
    DynamicCellDirective,
    FilterSelectComponent,
    FormErrorsComponent,
    IfBreakpointDirective,
    IfWritingAgentDirective,
    InputAutoFocusDirective,
    LoadingIndicatorComponent,
    MaskedValueComponent,
    MultiSummaryBlockComponent,
    PageComponent,
    ProgressBarComponent,
    RadioGroupComponent,
    ResultsTableComponent,
    ScrollToDirective,
    SearchChipsComponent,
    SuccessDialogComponent,
    SvgComponent,
    TileComponent,
    FormFooterComponent,
    InstructionalMessageComponent,
    EnvelopeComponent,
    FilterDescriptionComponent,
    AutocompleteComponent,
    AddressComponent,
    FundSelectorComponent,
    FundSelectorDialogComponent,
    CheckButtonRendererComponent,
    CheckTitleRendererComponent,
    RadioTitleHeaderRendererComponent,
    ComposableTableFooterComponent,
    ComposableTableHeaderComponent,
    ComposableTableRowsComponent,
    ComposableTableFooterLabelRendererComponent,
    DropdownRendererComponent,
    SecureDownloadDirective,
    ReviewBlockComponent,
    FileUploadComponent,
    // LiquidityComponent,
    // RpqComponent,
    // FinancialProfileComponent,
    // IncomeTypeWarningComponent,
    // MutualDisclosureComponent,
    // SupplementalDisclosureComponent,
    // PensionDisclosureComponent,
    // ErisaDisclosureComponent,
    // CurrentInvestmentsComponent,
    FundSelectionDropdownComponent,
    ...cellRenderers,
    ...pipes,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SumdayComponentModule,
    SumdayCommonModule,
    LoggedInUserModule,
    ...materialModules,
  ],
  exports: [
    SwitchComponent,
    AccordionComponent,
    AccordionListComponent,
    AccordionTableDirective,
    CardListComponent,
    ChecklistComponent,
    ContextMenuComponent,
    DrawerComponent,
    ExternalLinkWarnDirective,
    FilterSelectComponent,
    FormErrorsComponent,
    IfBreakpointDirective,
    IfWritingAgentDirective,
    InputAutoFocusDirective,
    LoadingIndicatorComponent,
    MaskedValueComponent,
    MultiSummaryBlockComponent,
    PageComponent,
    ProgressBarComponent,
    RadioGroupComponent,
    ResultsTableComponent,
    ScrollToDirective,
    SearchChipsComponent,
    SuccessDialogComponent,
    SvgComponent,
    TileComponent,
    FormFooterComponent,
    InstructionalMessageComponent,
    EnvelopeComponent,
    AutocompleteComponent,
    AddressComponent,
    FundSelectorComponent,
    CheckButtonRendererComponent,
    CheckTitleRendererComponent,
    RadioTitleHeaderRendererComponent,
    ComposableTableFooterComponent,
    ComposableTableHeaderComponent,
    ComposableTableRowsComponent,
    DropdownRendererComponent,
    SecureDownloadDirective,
    ReviewBlockComponent,
    FileUploadComponent,
    // LiquidityComponent,
    // RpqComponent,
    // FinancialProfileComponent,
    // IncomeTypeWarningComponent,
    // MutualDisclosureComponent,
    // SupplementalDisclosureComponent,
    // PensionDisclosureComponent,
    // ErisaDisclosureComponent,
    // CurrentInvestmentsComponent,
    FundSelectionDropdownComponent,
    ...cellRenderers,
    ...pipes,
    ...materialModules,
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  ],
})
export class SharedModule {}
