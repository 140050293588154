import { Pipe, PipeTransform } from "@angular/core";

const SSN = /\d{3}-\d{2}-\d{4}/g;
const EIN = /\d{2}-\d{7}/g;

@Pipe({ name: "lastFourTaxId", pure: true })
export class LastFourTaxIdPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (typeof value !== "string" || !(value.match(SSN) || value.match(EIN))) {
      return value;
    }
    return value.replace(/\d(?!\d{0,3}$)/g, "•");
  }
}
