import { HttpParams } from "@angular/common/http";
import { ActivatedRouteSnapshot, Data } from "@angular/router";

export const removeEmptyValues = (values: Record<string, any>) =>
  Object.entries(values)
    .filter(
      ([, value]) => value !== undefined && value !== null && value !== "",
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const createQuery = (values: Record<string, any>) =>
  String(new URLSearchParams(removeEmptyValues(values)));

export const encodeAsFormUrl = (values: Record<string, string>): string => {
  const params = Object.entries(values).reduce(
    (acc, [key, value]) => acc.set(key, value),
    new HttpParams(),
  );

  return params.toString();
};

export function getRouteData<T>(
  routeSnapshot: ActivatedRouteSnapshot,
  cb: (data: Data) => T | undefined,
): T | undefined {
  const routeData = cb(routeSnapshot.data);

  if (routeData) {
    return routeData;
  }

  if (!routeSnapshot.parent) {
    return undefined;
  }

  return getRouteData(routeSnapshot.parent, cb);
}

export function concatUrl(url: string, redirectTo: string) {
  const urlTokens = url.split("/");
  const redirectToTokens = redirectTo.split("/");

  let token = redirectToTokens.shift();

  while (token) {
    if (token !== "." && token !== "..") {
      redirectToTokens.unshift(token);
      break;
    }

    if (token === "..") {
      urlTokens.pop();
    }

    token = redirectToTokens.shift();
  }

  urlTokens.push(...redirectToTokens);

  return urlTokens.join("/");
}
