import { Component, Input } from "@angular/core";

import { Confirmation } from "../../shared/notifications/notification";

@Component({
  selector: "app-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"],
})
export class ConfirmationComponent {
  @Input() confirmation: Confirmation;

  onConfirm() {
    this.confirmation.options.onConfirm();
    this.confirmation.onDismiss();
  }

  onCancel() {
    if (this.confirmation.options.onCancel != null) {
      this.confirmation.options.onCancel();
    }
    this.confirmation.onDismiss();
  }
}
