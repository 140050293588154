import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService, TakeoverType } from "@sumday/common";
import { BehaviorSubject, from, of, race } from "rxjs";
import { delay, mapTo } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { TimeoutSchedulerService } from "../../shared/timeout-scheduler.service";

import {
  AuthResponse,
  AuthSsoService,
  REFRESH_STATE_PREFIX,
  TokenSet,
} from "./auth-sso.service";
import { AuthService } from "./auth.service";

/**
 * Delay before we display the token refresh prompt if there is no user activity.
 */
export const USER_IDLE_TIMEOUT_SECONDS = environment.userIdleTimeoutSeconds;

/**
 * Duration of the idle user prompt before we redirect to logout.
 */
export const PROMPT_DURATION_SECONDS = 60;

/**
 * Duration before the token expiration to call refresh method
 */
export const TOKEN_REFRESH_GUARD_SECONDS = 180;

/**
 * Key for the storage of the redirect URL.
 */
export const REDIRECT_TO_SESSION_STORAGE_KEY = "sso.redirectTo";

/**
 * Route we redirect to after logout.
 * Could be a page like "You've been successfully logged out".
 */
export const REDIRECT_TO_AFTER_LOGOUT = "/";

@Injectable()
export class PkceAuthService extends AuthService {
  private tokenSetSubj$ = new BehaviorSubject<TokenSet | undefined>(undefined);
  private tokenRefreshScheduler = this.timeoutSchedulerService.createScheduler(
    this.refreshToken.bind(this),
    true,
  );
  private userIdleScheduler = this.timeoutSchedulerService.createScheduler(
    this.promptForTokenRefresh.bind(this),
    true,
  );

  private userIdleTimeout = USER_IDLE_TIMEOUT_SECONDS;
  private tokenExpiredIn = 0;
  private promptDurationSeconds = PROMPT_DURATION_SECONDS;

  constructor(
    private authSsoService: AuthSsoService,
    private router: Router,
    private timeoutSchedulerService: TimeoutSchedulerService,
    private dialogService: DialogService,
  ) {
    super();
  }

  /**
   * Open the login page of the SSO.
   */
  async login(redirectTo: string) {
    this.resetTokenScheduler();
    this.resetUserIdleEventSubscription();
    sessionStorage.setItem(REDIRECT_TO_SESSION_STORAGE_KEY, redirectTo);
    await this.authSsoService.redirectToLogin();
  }

  async redirectToLogout(redirectTo: string) {
    this.resetTokenScheduler();
    this.resetUserIdleEventSubscription();
    this.authSsoService.redirectToLogout(redirectTo);
  }

  supportsLogout() {
    return this.authSsoService.canLogout();
  }

  getTokenForApi(tokenSet: TokenSet): string {
    return this.authSsoService.getTokenForApi(tokenSet);
  }

  setUserIdleTimeout(seconds: number | undefined): void {
    if ((seconds ?? 0) < 90) {
      console.warn("User Idle timeout cannot be less than 90 seconds");
      return;
    }
    this.userIdleTimeout = seconds ?? 90;
    this.resetUserIdleEventSubscription();
    this.setUserIdleEventSubscription();
  }

  setPromptDurationForTests(seconds: number | undefined): void {
    this.promptDurationSeconds = seconds ?? PROMPT_DURATION_SECONDS;
  }

  /**
   * Get the authorization code from the URL params, then fetch the access
   * token from the SSO and schedule its refresh.
   */
  async handleCallback() {
    const search = new URLSearchParams(location.search);
    const state = search.get("state");

    if (state && state.startsWith(REFRESH_STATE_PREFIX)) {
      this.handleRefreshCallback(search);
    } else {
      await this.handleAcquisitionCallback(search);
    }
  }

  private handleRefreshCallback(search: URLSearchParams) {
    try {
      const error = this.detectCallbackError(search);

      const response: AuthResponse = {
        error,
        code: search.get("code") || "",
        state: search.get("state") || "",
      };

      const message = {
        type: "authorization_response",
        response,
      };

      (parent || window).postMessage(message, location.origin);
    } catch (err) {
      let message: string | undefined;
      let stack: string | undefined;
      if (err instanceof Error) {
        message = err.message;
        stack = err.stack;
      } else {
        message = String(err);
      }

      console.error("Error while handling refresh callback", err);
      const response: AuthResponse = {
        error: {
          name: "ERROR",
          message,
          stack,
        },
        code: "",
        state: "",
      };

      (parent || window).postMessage(
        {
          type: "authorization_response",
          response,
        },
        location.origin,
      );
    }
  }

  private detectCallbackError(search: URLSearchParams): Error | undefined {
    if (search.has("error")) {
      const errorCode = search.get("error");
      const errorDescription = decodeURIComponent(
        search.get("error_description") || "",
      );

      return {
        name: errorCode || "",
        message: errorDescription,
      };
    }

    if (!search.has("code")) {
      return {
        name: "CODE_MISSING",
        message: "No code found in the query string",
      };
    }

    return undefined;
  }

  private async handleAcquisitionCallback(search: URLSearchParams) {
    try {
      const tokenSet = await this.authSsoService.handleCallback(search);
      // the page is redirected
      if (tokenSet == null) return;
      this.setTokenScheduler(tokenSet);
      this.setUserIdleEventSubscription();

      const redirectTo = this.getRedirectUrlAndForgetIt();
      this.router.navigateByUrl(redirectTo || "/", { replaceUrl: true });
    } catch (err) {
      console.error("Error while handling acquisition callback:", err);
      await this.router.navigateByUrl("/error", { replaceUrl: true });
    }
  }

  private getRedirectUrlAndForgetIt() {
    try {
      const redirectTo = sessionStorage.getItem(
        REDIRECT_TO_SESSION_STORAGE_KEY,
      );
      sessionStorage.removeItem(REDIRECT_TO_SESSION_STORAGE_KEY);
      return redirectTo;
    } catch (err) {
      return undefined;
    }
  }

  async promptForTokenRefresh() {
    race(
      of(false).pipe(delay(this.promptDurationSeconds * 1000)),
      from(
        this.dialogService.showConfirmation({
          title: "Still there?",
          subtitle: `
            It's been quiet around here for a few minutes.
            Give us a sign of life so we don't close your session.
            `,
          type: TakeoverType.Timeout,
        }),
      ).pipe(mapTo(true)),
    ).subscribe((shouldRefreshToken) => {
      if (!shouldRefreshToken) {
        this.redirectToLogout("/login/logout.html");
      } else {
        this.updateUserIdleEventSubscription();
      }
    });
  }

  /**
   * Ask the SSO for a new token, then schedule a token refresh. If the SSO
   * returns an error, remove the token from local storage so the user is
   * considered logged out on our side too.
   */
  async refreshToken(): Promise<boolean> {
    try {
      console.warn("Auth Token refresh");
      const tokenSet = await this.authSsoService.refreshToken();
      if (tokenSet) this.setTokenScheduler(tokenSet);
      return true;
    } catch (err) {
      console.error("Error while refreshing token:", err);
      this.resetTokenScheduler();
      return false;
    }
  }

  get tokenSet$() {
    return this.tokenSetSubj$.asObservable();
  }

  private setTokenScheduler(tokenSet: TokenSet): void {
    this.tokenSetSubj$.next(tokenSet);
    this.tokenExpiredIn = tokenSet.expires_in;
    const toExpireInMilliseconds =
      (this.tokenExpiredIn - TOKEN_REFRESH_GUARD_SECONDS) * 1000;

    this.tokenRefreshScheduler.schedule(toExpireInMilliseconds);
    console.warn(
      "Auth Token scheduler set, Token will be refreshed at " +
        new Date(new Date().getTime() + toExpireInMilliseconds),
    );
  }

  private resetTokenScheduler(): void {
    console.warn("Auth Token refresh cancelled");
    this.tokenRefreshScheduler.cancel();
    if (this.tokenSetSubj$.value) {
      this.tokenSetSubj$.next(undefined);
    }
  }

  private setUserIdleEventSubscription(): void {
    window.addEventListener(
      "click",
      this.updateUserIdleEventSubscription.bind(this),
      false,
    );
    window.addEventListener(
      "keypress",
      this.updateUserIdleEventSubscription.bind(this),
      false,
    );
    this.updateUserIdleEventSubscription();
    console.warn(`User Idle timeout set to ${this.userIdleTimeout} seconds`);
  }

  private resetUserIdleEventSubscription(): void {
    window.removeEventListener(
      "click",
      this.updateUserIdleEventSubscription.bind(this),
      false,
    );
    window.removeEventListener(
      "keypress",
      this.updateUserIdleEventSubscription.bind(this),
      false,
    );
    this.userIdleScheduler.cancel();
    console.warn("User Idle refresh cancelled");
  }

  private updateUserIdleEventSubscription(): void {
    this.userIdleScheduler.cancel();
    this.userIdleScheduler.schedule(this.userIdleTimeout * 1000);
  }
}
