import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DateInterceptor, SumdayCommonModule } from "@sumday/common";
import { SumdayComponentModule } from "@sumday/components";
import { Angulartics2Module } from "angulartics2";

import { environment } from "../../environments/environment.hmr";
import { SharedModule } from "../shared/shared.module";

import { AppParametersService } from "./app-parameters.service";
import { authServiceProvider } from "./auth/auth.service.provider";
import { CallbackComponent } from "./auth/callback.component";
import { CdnImageDirective } from "./directives/cdn-image.directive";
import { ErrorPageComponent } from "./error/error-page.component";
import { FooterComponent } from "./footer/footer.component";
import { BackButtonComponent } from "./header/back-button/back-button.component";
import { HeaderLinkComponent } from "./header/header-link.component";
import { HeaderComponent } from "./header/header.component";
import { AuthTokenInterceptor } from "./http-interceptors/auth-token.interceptor";
import { AuthenticationInterceptor } from "./http-interceptors/authentication-interceptor";
import { ServerErrorInterceptor } from "./http-interceptors/server-error.interceptor";
import { XRequestedByInterceptor } from "./http-interceptors/x-request-by-interceptor";
import { XSamFakeAuthInterceptor } from "./http-interceptors/x-sam-fake-auth-interceptor";
import { LoggedInUserModule } from "./logged-in-user/logged-in-user.module";
import { MaintenancePageComponent } from "./maintenance-page/maintenance-page.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ConfirmationComponent } from "./notifications/confirmation.component";
import { LegacySiteNotificationComponent } from "./notifications/legacy-site-notification/legacy-site-notification.component";
import { NotificationComponent } from "./notifications/notification.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { RedirectPageComponent } from "./redirect/redirect-page.component";
import { TakeoverLoaderComponent } from "./takeover-loader/takeover-loader.component";
import { BrowserCompatibilityComponent } from "./browsercompatibility/browsercompatibility.component";

const httpInterceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: XRequestedByInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DateInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ServerErrorInterceptor,
    multi: true,
  },
];

if (environment.allowFakeAuth) {
  httpInterceptors.push({
    provide: HTTP_INTERCEPTORS,
    useClass: XSamFakeAuthInterceptor,
    multi: true,
  });
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    HeaderLinkComponent,
    CallbackComponent,
    ErrorPageComponent,
    BrowserCompatibilityComponent,
    NotificationComponent,
    NotificationsComponent,
    LegacySiteNotificationComponent,
    BackButtonComponent,
    ConfirmationComponent,
    RedirectPageComponent,
    MaintenancePageComponent,
    CdnImageDirective,
    TakeoverLoaderComponent,
  ],
  imports: [
    CommonModule,
    SumdayCommonModule,
    SumdayComponentModule,
    LoggedInUserModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: "callback",
        pathMatch: "full",
        component: CallbackComponent,
      },
      {
        path: "redirect",
        pathMatch: "full",
        component: RedirectPageComponent,
      },
    ]),
    Angulartics2Module.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    ErrorPageComponent,
    BrowserCompatibilityComponent,
    NotificationComponent,
    NotificationsComponent,
    ConfirmationComponent,
    MaintenancePageComponent,
    CdnImageDirective,
    TakeoverLoaderComponent,
  ],
})
export class ShellModule {
  static forRoot(): ModuleWithProviders<ShellModule> {
    return {
      ngModule: ShellModule,
      providers: [...httpInterceptors, authServiceProvider],
    };
  }

  constructor(private appParams: AppParametersService) {
    this.appParams.fillData();
  }
}
