import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  TitleCasePipe,
} from "@angular/common";
import { Injectable } from "@angular/core";

import { fundFamilyMappings } from "../dynamic-mappers/fund-family.mapping";
import { phoneNumberFormatter } from "../number.format";
import { AccountingPipe } from "../pipes/accounting";
import { AccountingCurrencyPipe } from "../pipes/accounting-currency";
import { AccountingSharesPipe } from "../pipes/accounting-shares";

import { Transform } from "./transform";

@Injectable({
  providedIn: "root",
})
export class Transforms {
  constructor(
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private accountingPipe: AccountingPipe,
    private accountingCurrencyPipe: AccountingCurrencyPipe,
    private accountingSharesPipe: AccountingSharesPipe,
    private titleCasePipe: TitleCasePipe,
  ) {}

  getDecimalTransform({
    minIntegerDigits = 1,
    minFractionDigits = 0,
    maxFractionDigits = 3,
    locale = "en-US",
  } = {}): Transform {
    return (value) =>
      this.decimalPipe.transform(
        value,
        `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`,
        locale,
      );
  }

  getNilValueTransform(text: string, transform: Transform): Transform {
    return (value) => (value == null ? text : transform(value));
  }

  getPercentageTransform({
    minIntegerDigits = 1,
    minFractionDigits = 0,
    maxFractionDigits = 2,
    locale = "en-US",
  } = {}): Transform {
    const decimalTransform = this.getDecimalTransform({
      minIntegerDigits,
      minFractionDigits,
      maxFractionDigits,
      locale,
    });
    return (value) => `${decimalTransform(value)}%`;
  }

  roundToTwo(): Transform {
    return this.getDecimalTransform({
      minIntegerDigits: 1,
      minFractionDigits: 2,
      maxFractionDigits: 2,
      locale: "en-US",
    });
  }

  getCurrencyTransform(digitsInfo?: string): Transform {
    return (value) =>
      this.currencyPipe.transform(value, undefined, undefined, digitsInfo);
  }

  getMonthYearDateTransform({ locale = "en-US" } = {}): Transform {
    return (value) => {
      const localValue = `${value}-01T00:00:00`;
      return this.datePipe.transform(localValue, "MM/yyyy", undefined, locale);
    };
  }

  getDateTransform({
    format = "medium",
    locale = "en-US",
    timezone,
  }: { format?: string; locale?: string; timezone?: string } = {}): Transform {
    return (value) => this.datePipe.transform(value, format, timezone, locale);
  }

  getDefaultTransform({
    defaultValue,
    baseTransform,
  }: {
    defaultValue: string;
    baseTransform: Transform;
  }): Transform {
    return (value) => (value != null ? baseTransform(value) : defaultValue);
  }

  getAccountingTransform(): Transform {
    return (value) => this.accountingPipe.transform(value);
  }

  getAccountingCurrencyTransform(): Transform {
    return (value) => this.accountingCurrencyPipe.transform(value);
  }

  getAccountingSharesTransform(): Transform {
    return (value) => this.accountingSharesPipe.transform(value);
  }

  getChainedTransform(...transforms: Transform[]): Transform {
    return (value) =>
      transforms.reduce((current, transform) => transform(current), value);
  }

  getPhoneNumberTransform(): Transform {
    return (value) => phoneNumberFormatter(value);
  }

  getTitleCaseTransform(): Transform {
    return (value) => this.titleCasePipe.transform(value);
  }

  mapFundFamilyTransform(): Transform {
    return (value: string | null | undefined) =>
      fundFamilyMappings.mapName(value);
  }
}
