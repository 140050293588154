import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { TokenSet } from "./auth-sso.service";

export abstract class AuthService {
  /**
   * Open the login page of the SSO.
   */
  abstract login(redirectTo: string): Promise<void>;

  abstract supportsLogout(): boolean;

  abstract redirectToLogout(redirectTo: string): Promise<void>;

  /**
   * Get the authorization code from the URL params, then fetch the access
   * token from the SSO and schedule its refresh.
   */
  abstract handleCallback(): Promise<void>;

  /**
   * Ask the SSO for a new token, then schedule a token refresh. If the SSO
   * returns an error, remove the token from local storage so the user is
   * considered logged out on our side too.
   */
  abstract refreshToken(): Promise<boolean>;

  abstract getTokenForApi(tokenSet: TokenSet): string;

  get isAuthenticated$() {
    return this.tokenSet$.pipe(map(Boolean));
  }

  abstract get tokenSet$(): Observable<TokenSet | undefined>;

  abstract promptForTokenRefresh(): Promise<void>;

  abstract setUserIdleTimeout(seconds: number | undefined): void;
}
