import { Component, Input } from "@angular/core";

import { Transforms } from "../../../transforms/transforms";

import { CellRendererDirective } from "./cell-renderer.directive";

@Component({
  selector: "app-price-change-cell-renderer",
  template: `
    <ng-container *ngIf="rawValue != null">
      <i
        [ngClass]="{
          icon: true,
          'icon-increase': isIncrease,
          'icon-decrease': isDecrease,
        }"
      ></i>
      <app-router-link-cell
        [rawValue]="value"
        [routerLink]="routerLink"
      ></app-router-link-cell>
    </ng-container>
    <ng-container *ngIf="rawValue == null">
      <span>N/A</span>
    </ng-container>
  `,
  styleUrls: ["./price-change-cell-renderer.component.scss"],
})
export class PriceChangeCellRendererComponent extends CellRendererDirective {
  @Input() routerLink: string;

  get value() {
    return this.transforms.getCurrencyTransform()(Math.abs(this.rawValue));
  }

  get isIncrease() {
    return this.rawValue > 0;
  }

  get isDecrease() {
    return this.rawValue < 0;
  }

  constructor(private transforms: Transforms) {
    super();
  }
}
