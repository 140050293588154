<app-page pageTitle="Maintenance" [hideTitle]="true">
  <div class="notification-container">
    <span class="icon icon-circle-wrench"></span>
    <div class="message-section">
      <h2>We’re doing some site maintenance.</h2>
      <p>
        While we complete this, some functionality will be unavailable. Please
        check back in a couple of hours.
      </p>
    </div>
  </div>
</app-page>
