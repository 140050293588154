import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ErrorPageComponent } from "./shell/error/error-page.component";
import { MaintenancePageComponent } from "./shell/maintenance-page/maintenance-page.component";
import { NotFoundComponent } from "./shell/not-found/not-found.component";
import { BrowserCompatibilityComponent } from "./shell/browsercompatibility/browsercompatibility.component";

const routes: Routes = [
  {
    path: "error",
    component: ErrorPageComponent,
  },
  {
    path: "browsercompatibility",
    component: BrowserCompatibilityComponent,
  },
  {
    path: "maintenance",
    component: MaintenancePageComponent,
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./touchdown/touchdown.module").then((m) => m.TouchdownModule),
  },
  {
    path: "serviceforms",
    loadChildren: () =>
      import("./serviceforms/serviceforms.module").then(
        (m) => m.ServiceformsModule,
      ),
  },
  {
    path: "research",
    loadChildren: () =>
      import("./research/research.module").then((m) => m.ResearchModule),
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: "always",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
