import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { BackButtonInfo, BackButtonService } from "./back-button.service";

@Component({
  selector: "app-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit {
  backButtonInfo$: Observable<BackButtonInfo | null>;

  constructor(backButtonService: BackButtonService) {
    this.backButtonInfo$ = backButtonService.backButtonInfo$;
  }

  ngOnInit() {}
}
