<app-notification class="screen-only" [notification]="confirmation">
  <div class="button-holder">
    <button class="btn-pill" (click)="onConfirm()">
      {{ confirmation.options.confirmText }}
    </button>
    <button class="btn-pill btn-dark-inverse" (click)="onCancel()">
      {{ confirmation.options.cancelText }}
    </button>
  </div>
</app-notification>
