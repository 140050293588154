<div class="app-page-wrapper">
  <ng-content select="[pre-header]"></ng-content>
  <sumday-grid container>
    <sumday-grid [desktop]="pageDesktopSize">
      <div
        class="page-title-container"
        [ngClass]="{ 'screen-reader-only': hideTitle }"
        *ngIf="pageTitle"
      >
        <div class="titles">
          <div [class]="'title-container ' + extraTitleClasses">
            <h1 class="title" [attr.data-qa]="'page-title'">
              {{ pageTitle }}
            </h1>
            <ng-content select="[title-tooltip]"></ng-content>
          </div>
          <p
            *ngIf="subTitle"
            [class]="'subtitle ' + extraSubtitleClasses"
            [attr.data-qa]="'page_subtitle'"
          >
            {{ subTitle }}
            <ng-content select="[subtitle-content]"></ng-content>
          </p>
          <p *ngIf="!subTitle"></p>
        </div>
        <ng-container *ngIf="!hideTitle">
          <ng-content select="[title-side]"></ng-content>
        </ng-container>
      </div>
    </sumday-grid>
    <sumday-grid [desktop]="4" class="message-container">
      <ng-content select="[info-message]"></ng-content>
    </sumday-grid>
  </sumday-grid>
  <ng-content></ng-content>
</div>
