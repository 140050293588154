<footer class="screen-only">
  <sumday-grid container>
    <sumday-grid [desktop]="12" [tablet]="12" [mobile]="12">
      <a class="logo-link" routerLink="/">
        <img
          class="primerica-logo"
          src="/assets/primerica-logo-small.jpg"
          alt="Primerica"
        />
      </a>
    </sumday-grid>
    <sumday-grid [desktop]="5" [tablet]="12" [mobile]="12">
      <div class="logo-legal-text">
        <p>
          Your use of this site signifies that you accept our
          <a
            target="_blank"
            rel="noopener"
            href="{{ pdfpath }}/SAM_Terms_and_Conditions.pdf"
          >
            Terms and Conditions of Use
          </a>
          .
        </p>
        <p>Copyright &copy; 2002-{{ currentYear }} Primerica.</p>
      </div>
    </sumday-grid>

    <sumday-grid [desktop]="2" [tablet]="4" [mobile]="12">
      <div class="links">
        <ul>
          <li>
            <strong>Additional links</strong>
          </li>
          <li>
            <a target="_blank" rel="noopener" (click)="login()">
              Shareholder.Primerica.com
              <span class="icon icon-right-caret"></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener"
              href="{{ pdfpath }}/PRIVACY_AND_SECURITY.pdf"
            >
              Privacy
              <span class="icon icon-right-caret"></span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener"
              href="{{ htmlpath }}/cookiepolicy.html"
            >
              Cookie Policy
              <span class="icon icon-right-caret"></span>
            </a>
          </li>
          <li>
            <a rel="noopener" href="/browsercompatibility">
              Browser Compatibility
              <span class="icon icon-right-caret"></span>
            </a>
          </li>
        </ul>
      </div>
    </sumday-grid>
    <sumday-grid [desktop]="2" [tablet]="4" [mobile]="12">
      <div class="links">
        <ul>
          <li>
            <strong>Need help?</strong>
          </li>
          <li>
            <span class="contact-info">Call </span>
            <a class="support-number" href="tel:+1-800-665-8677"
              >1-800-665-8677</a
            >
            <br />
            <span class="contact-info">Monday – Friday 8am–8pm ET</span>
          </li>
        </ul>
      </div>
    </sumday-grid>
  </sumday-grid>
</footer>
