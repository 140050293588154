import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { LoaderService } from "./loader.service";

@Component({
  selector: "app-takeover-loader",
  templateUrl: "./takeover-loader.component.html",
  styleUrls: ["./takeover-loader.component.scss"],
})
export class TakeoverLoaderComponent implements OnInit {
  isLoading$: Observable<boolean>;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.isLoading$ = this.loaderService.isLoading$;
  }
}
