export type PerformanceCharts =
  | "total-balance"
  | "time-weighted-returns"
  | "market-value"
  | "holdings"
  | "allocations"
  | "account-returns";

export interface AccountDetailsUrls {
  overview: () => string;
  position: (symbol?: string | null) => string;
  profile: () => {
    baseUrl: () => string;
    accountRecord: () => string;
    accountOptions: () => string;
    addBankAccount: () => string;
    editBankAccount: (id?: string | null) => string;
    reviewAddedBankAccount: (id?: string | null) => string;
    incomeOptions: () => string;
    beneficiaries: () => string;
    editBeneficiaries: (id?: string | null) => string;
    bankDrafts: () => string;
    telephoneOptions: () => string;
    editTelephoneOptions: () => string;
    rmdOptions: () => string;
    editRmdOptions: () => string;
    correspondence: () => string;
  };
  activity: () => {
    baseUrl: () => string;
    summary: () => string;
    history: () => {
      baseUrl: () => string;
      bySymbol: (symbol: string) => string;
    };
  };
  statements: () => {
    baseUrl: () => string;
    quarterlyStatements: () => string;
    taxForms: () => string;
    transcripts: () => string;
    averageCost: () => string;
  };
  allocations: () => {
    baseUrl: () => string;
    edit: () => string;
    editRebalance: () => string;
    rebalanceReview: () => string;
    rebalanceReceipt: () => string;
  };
  estar: () => {
    baseUrl: () => string;
    edit: () => string;
    riskProfile: () => string;
    liquidity: () => string;
    currentFinancialProducts: () => string;
    secondaryContacts: () => string;
    investmentIncome: () => string;
  };
  trade: () => {
    baseUrl: () => string;
    buyFunds: () => {
      baseUrl: () => string;
      purchaseAmount: () => string;
      incomeOptions: () => string;
      reviewConfirm: () => string;
      receipt: () => string;
    };
    buyFundsUrls: () => string[];
    exchangeFunds: () => {
      baseUrl: () => string;
      incomeOptions: () => string;
      reviewConfirm: () => string;
      receipt: () => string;
    };
    exchangeFundsUrls: () => string[];
    sellFunds: () => {
      baseUrl: () => string;
      sellAmount: () => string;
      proceedsAndDelivery: () => string;
      reviewConfirm: () => string;
      taxWithholding: () => string;
      receipt: () => string;
    };
    sellFundsUrls: () => string[];
    spp: () => {
      baseUrl: () => string;
      edit: (symbol: string) => string;
      review: (symbol: string) => string;
      add: () => string;
    };
    swp: () => {
      baseUrl: () => string;
      edit: (symbol: string) => string;
      review: (symbol: string) => string;
    };
  };
  editProfile: () => string;
  editIncomeOptions: (fund: string) => string;
  confirmIncomeOptionsEdits: (fund: string) => string;
  editAlternateOwner: (id: string) => string;
  addSecondaryContact: () => string;
  editSecondaryContact: (id: string) => string;
  performance: (chart?: PerformanceCharts) => string;
}

function applyHash(url: string, hash?: string) {
  if (!hash) {
    return url;
  }

  if (url.lastIndexOf("/") === url.length - 1) {
    url = url.substring(0, url.length - 1);
  }

  return `${url}#${hash}`;
}

function generateNextUrlFunction(
  sectionNames: string[],
  urls: string[],
  defaultUrl,
) {
  return (currentStep?: string) => {
    const i = ([undefined] as (string | undefined)[])
      .concat(sectionNames)
      .indexOf(currentStep);
    if (i === -1 || i >= urls.length) {
      return defaultUrl;
    }
    return urls[i];
  };
}

function generatePreviousUrlFunction(
  sectionNames: string[],
  urls: string[],
  defaultUrl,
) {
  return (currentStep: string) => {
    const i = sectionNames.slice(1).indexOf(currentStep);
    if (i === -1) {
      return defaultUrl;
    }
    return urls[i];
  };
}

function isENotificationRequired(application: Api.AccountApplication) {
  const eNotification = application?.preferences?.eNotification;
  return eNotification !== "none" && eNotification != null;
}

const ACCOUNT_OPTIONS_SECTIONS = ["configureAccount", "exampleEnvelope"];

const GETTING_STARTED_SECTIONS = [
  "readyToBegin",
  "eDeliveryOptions",
  "otherDeliveryOptions",
  "brokerageAdvisory",
];

const INVESTMENT_INFO_SECTIONS = [
  "accountFunding",
  "bankDrafts",
  "transferAssets",
  "investmentOptions",
  "letterOfIntent",
];

const FINANCIAL_INFO_SECTIONS = [
  "financialProfile",
  "riskProfile",
  "liquidity",
  "currentInvestments",
  "investmentIncome",
];

const SIGNING_SECTIONS = [
  "review",
  "disclosures",
  "eNotification",
  "investorSignature",
  "repSignature",
  "finalReview",
  "accountSummary",
];

export function getAccountDetailsUrls(accountId: string): AccountDetailsUrls {
  const baseUrl = `/accounts/${accountId}`;
  const profileUrl = `${baseUrl}/profile`;
  const activityBaseUrl = `${baseUrl}/activity`;
  const statementsBaseUrl = `${baseUrl}/statements`;
  const allocationsUrl = `${baseUrl}/allocations`;
  const tradeBaseUrl = `${baseUrl}/trade`;
  const overviewUrl = `${baseUrl}/overview`;
  const historyBaseUrl = `${baseUrl}/activity/history`;
  const buyUrl = `${tradeBaseUrl}/buy-funds`;
  const exchangeUrl = `${tradeBaseUrl}/exchange-funds`;
  const sellUrl = `${tradeBaseUrl}/sell-funds`;
  const sppUrl = `${tradeBaseUrl}/spp`;
  const swpUrl = `${tradeBaseUrl}/swp`;
  const estarBaseUrl = `${baseUrl}/estar`;

  return {
    overview: () => overviewUrl,
    activity: () => ({
      baseUrl: () => activityBaseUrl,
      summary: () => `${activityBaseUrl}/summary`,
      history: () => ({
        baseUrl: () => `${activityBaseUrl}/history`,
        bySymbol: (symbol: string) => `${historyBaseUrl}/${symbol}`,
      }),
    }),
    statements: () => ({
      baseUrl: () => statementsBaseUrl,
      quarterlyStatements: () => `${statementsBaseUrl}/quarterly`,
      taxForms: () => `${statementsBaseUrl}/tax-forms`,
      transcripts: () => `${statementsBaseUrl}/transcripts`,
      averageCost: () => `${statementsBaseUrl}/average-cost-report`,
    }),
    allocations: () => ({
      baseUrl: () => allocationsUrl,
      edit: () => `${allocationsUrl}/edit`,
      editRebalance: () => `${allocationsUrl}/edit-rebalance`,
      rebalanceReview: () => `${allocationsUrl}/rebalance-review`,
      rebalanceReceipt: () => `${allocationsUrl}/rebalance-receipt`,
    }),
    estar: () => ({
      baseUrl: () => estarBaseUrl,
      edit: () => `${estarBaseUrl}/edit`,
      riskProfile: () => `${estarBaseUrl}/risk-profile`,
      liquidity: () => `${estarBaseUrl}/liquidity`,
      currentFinancialProducts: () =>
        `${estarBaseUrl}/current-financial-products`,
      secondaryContacts: () => `${estarBaseUrl}/secondary-contacts`,
      investmentIncome: () => `${estarBaseUrl}/investment-income`,
    }),
    trade: () => ({
      baseUrl: () => tradeBaseUrl,
      buyFunds: () => ({
        baseUrl: () => buyUrl,
        purchaseAmount: () => `${buyUrl}/purchase-amount`,
        incomeOptions: () => `${buyUrl}/income-options`,
        reviewConfirm: () => `${buyUrl}/review-confirm`,
        receipt: () => `${buyUrl}/receipt`,
      }),
      buyFundsUrls: () => [
        buyUrl,
        `${buyUrl}/purchase-amount`,
        `${buyUrl}/income-options`,
        `${buyUrl}/review-confirm`,
        `${buyUrl}/receipt`,
      ],
      exchangeFunds: () => ({
        baseUrl: () => exchangeUrl,
        incomeOptions: () => `${exchangeUrl}/income-options`,
        reviewConfirm: () => `${exchangeUrl}/review-confirm`,
        receipt: () => `${exchangeUrl}/receipt`,
      }),
      exchangeFundsUrls: () => [
        exchangeUrl,
        `${exchangeUrl}/income-options`,
        `${exchangeUrl}/review-confirm`,
      ],
      sellFunds: () => ({
        baseUrl: () => sellUrl,
        sellAmount: () => `${sellUrl}/sell-amount`,
        proceedsAndDelivery: () => `${sellUrl}/proceeds-and-delivery`,
        reviewConfirm: () => `${sellUrl}/review-confirm`,
        taxWithholding: () => `${sellUrl}/tax-withholding`,
        receipt: () => `${sellUrl}/receipt`,
      }),
      sellFundsUrls: () => [
        sellUrl,
        `${sellUrl}/sell-amount`,
        `${sellUrl}/proceeds-and-delivery`,
        `${sellUrl}/tax-withholding`,
        `${sellUrl}/review-confirm`,
      ],
      spp: () => ({
        baseUrl: () => sppUrl,
        edit: (symbol: string) => `${sppUrl}/edit/${symbol}`,
        review: (symbol: string) => `${sppUrl}/review/${symbol}`,
        add: () => `${sppUrl}/add`,
      }),
      swp: () => ({
        baseUrl: () => swpUrl,
        edit: (symbol: string) => `${swpUrl}/edit/${symbol}`,
        review: (symbol: string) => `${swpUrl}/review/${symbol}`,
      }),
    }),
    performance: (chart?) => applyHash(`${overviewUrl}/performance/`, chart),
    position: (symbol?: string | null) => `${overviewUrl}/position/${symbol}`,
    profile: () => ({
      baseUrl: () => profileUrl,
      accountRecord: () => `${profileUrl}/account-record`,
      accountOptions: () => `${profileUrl}/account-options`,
      addBankAccount: () => `${profileUrl}/add-bank-account`,
      editBankAccount: (id?: string | null) =>
        `${profileUrl}/edit-bank-account/${id}`,
      reviewAddedBankAccount: (id?: string | null) =>
        `${profileUrl}/review-added-bank-account/${id}`,
      incomeOptions: () => `${profileUrl}/income-options`,
      beneficiaries: () => `${profileUrl}/beneficiaries`,
      editBeneficiaries: (id?: string | null) =>
        `${profileUrl}/edit-beneficiaries/${id}`,
      bankDrafts: () => `${profileUrl}/bank-drafts`,
      telephoneOptions: () => `${profileUrl}/telephone-options`,
      editTelephoneOptions: () => `${profileUrl}/edit-telephone-options`,
      rmdOptions: () => `${profileUrl}/rmd-options`,
      editRmdOptions: () => `${profileUrl}/edit-rmd-options`,
      correspondence: () => `${profileUrl}/correspondence`,
    }),
    editProfile: () => `${profileUrl}/edit`,
    editIncomeOptions: (fund: string) =>
      `${profileUrl}/edit-income-options/${fund}`,
    confirmIncomeOptionsEdits: (fund: string) =>
      `${profileUrl}/confirm-income-options-edits/${fund}`,
    editAlternateOwner: (id: string) =>
      `${profileUrl}/edit-alternate-owner/${id}`,
    addSecondaryContact: () => `${profileUrl}/add-secondary-contact`,
    editSecondaryContact: (id: string) =>
      `${profileUrl}/edit-secondary-contact/${id}`,
  };
}

export interface ApplicationHubUrls {
  baseUrl: () => string;
  gettingStartedBaseUrl: () => string;
  gettingStartedPageUrls: () => string[];
  nextGettingStartedPageUrl: (currentStep: string) => string;
  previousGettingStartedPageUrl: (currentStep: string) => string;
  accountSetupPageUrl: () => string;
  accountTypePageUrl: () => string;
  accountPersonalInfoPageUrl: (index: number) => string;
  accountSecondaryContactsPageUrl: () => string;
  accountBeneficiariesPageUrl: () => string;
  piPreferencesPageUrl: () => string;
  investmentInfoPageUrls: () => string[];
  nextInvestmentInfoPageUrl: (currentStep: string) => string;
  previousInvestmentInfoPageUrl: (currentPage: string) => string;
  financialInfoPageUrls: () => string[];
  nextFinancialInfoPageUrl: (currentStep: string) => string;
  previousFinancialInfoPageUrl: (currentStep: string) => string;
  signingPageUrls: (application: Api.AccountApplication) => string[];
  nextSigningPageUrl: (
    currentStep: string,
    application: Api.AccountApplication,
  ) => string;
  previousSigningPageUrl: (
    currentStep: string,
    application: Api.AccountApplication,
  ) => string;
  accountOptionsUrls: () => string[];
  nextAccountOptionsPageUrl: (currentStep: string) => string;
  previousAccountOptionsPageUrl: (currentStep: string) => string;
  accountOptionsBaseUrl: () => string;
  investmentInfoBaseUrl: () => string;
  financialInfoBaseUrl: () => string;
  signingBaseUrl: () => string;
}

export interface RepToolsUrls {
  baseUrl: () => string;
  contactList: () => string;
  repContactList: (repId: string) => string;
  applicationList: () => string;
  repApplicationList: (repId: string) => string;
  createGroupPlan: () => string;
  dateOfLastContact: () => string;
  applicationHub: (applicationId: string) => ApplicationHubUrls;
}

export function getRepToolsUrls(): RepToolsUrls {
  const baseUrl = "/tools";

  return {
    baseUrl: () => baseUrl,
    contactList: () => `${baseUrl}/contact-center/contacts`,
    repContactList: (repId) => `${baseUrl}/contact-center/${repId}/contacts`,
    applicationList: () => `${baseUrl}/contact-center/applications`,
    repApplicationList: (repId) =>
      `${baseUrl}/contact-center/${repId}/applications`,
    createGroupPlan: () => `${baseUrl}/create-group-plan`,
    dateOfLastContact: () => `${baseUrl}/date-of-last-contact`,
    applicationHub: (applicationId: string) => {
      const appHubBaseUrl = `${baseUrl}/applications/${applicationId}`;
      const gettingStartedBaseUrl = `${baseUrl}/applications/${applicationId}/getting-started`;
      const gettingStartedPageUrls = [
        `${gettingStartedBaseUrl}/ready-to-begin`,
        `${gettingStartedBaseUrl}/e-delivery-options`,
        `${gettingStartedBaseUrl}/other-delivery-options`,
        `${gettingStartedBaseUrl}/brokerage-vs-advisory`,
      ];
      const investmentInfoBaseUrl = `${appHubBaseUrl}/investment-info`;
      const investmentInfoPageUrls = [
        `${investmentInfoBaseUrl}/account-funding`,
        `${investmentInfoBaseUrl}/bank-drafts`,
        `${investmentInfoBaseUrl}/transfer-assets`,
        `${investmentInfoBaseUrl}/investment-options`,
        `${investmentInfoBaseUrl}/letter-of-intent`,
      ];
      const financialInfoBaseUrl = `${appHubBaseUrl}/financial-info`;
      const financialInfoPageUrls = [
        `${financialInfoBaseUrl}/financial-profile`,
        `${financialInfoBaseUrl}/risk-profile`,
        `${financialInfoBaseUrl}/liquidity`,
        `${financialInfoBaseUrl}/current-investments`,
        `${financialInfoBaseUrl}/investment-income`,
      ];
      const signingBaseUrl = `${appHubBaseUrl}/sign`;
      const signingPageUrls = [
        `${signingBaseUrl}/review`,
        `${signingBaseUrl}/disclosures`,
        `${signingBaseUrl}/e-notification`,
        `${signingBaseUrl}/investor-signature`,
        `${signingBaseUrl}/rep-signature`,
        `${signingBaseUrl}/final-review`,
        `${signingBaseUrl}/summary`,
      ];
      const accountOptionsBaseUrl = `${appHubBaseUrl}/account-options`;
      const accountOptionsUrls = [
        `${accountOptionsBaseUrl}/configure-account`,
        `${accountOptionsBaseUrl}/example-envelope`,
      ];

      return {
        baseUrl: () => appHubBaseUrl,
        accountOptionsBaseUrl: () => `${accountOptionsBaseUrl}`,
        accountSetupPageUrl: () => `${appHubBaseUrl}/account-setup`,
        accountTypePageUrl: () => `${appHubBaseUrl}/account-type`,
        accountPersonalInfoPageUrl: (index: number) =>
          `${appHubBaseUrl}/personal-info/${index + 1}`,
        accountSecondaryContactsPageUrl: () =>
          `${appHubBaseUrl}/secondary-contacts`,
        accountBeneficiariesPageUrl: () => `${appHubBaseUrl}/beneficiaries`,
        piPreferencesPageUrl: () => `${appHubBaseUrl}/preferences`,
        gettingStartedBaseUrl: () => `${gettingStartedBaseUrl}`,
        gettingStartedPageUrls: () => gettingStartedPageUrls,
        nextGettingStartedPageUrl: generateNextUrlFunction(
          GETTING_STARTED_SECTIONS,
          gettingStartedPageUrls,
          appHubBaseUrl,
        ),
        previousGettingStartedPageUrl: generatePreviousUrlFunction(
          GETTING_STARTED_SECTIONS,
          gettingStartedPageUrls,
          appHubBaseUrl,
        ),
        investmentInfoBaseUrl: () => `${investmentInfoBaseUrl}`,
        investmentInfoPageUrls: () => investmentInfoPageUrls,
        financialInfoBaseUrl: () => `${financialInfoBaseUrl}`,
        financialInfoPageUrls: () => financialInfoPageUrls,
        signingBaseUrl: () => `${signingBaseUrl}`,
        signingPageUrls: (application: Api.AccountApplication) => {
          if (!isENotificationRequired(application)) {
            const sectionIndex = SIGNING_SECTIONS.findIndex(
              (section) => section === "eNotification",
            );
            return signingPageUrls.filter(
              (url, index) => sectionIndex !== index,
            );
          }
          return signingPageUrls;
        },
        nextInvestmentInfoPageUrl: generateNextUrlFunction(
          INVESTMENT_INFO_SECTIONS,
          investmentInfoPageUrls,
          appHubBaseUrl,
        ),
        previousInvestmentInfoPageUrl: generatePreviousUrlFunction(
          INVESTMENT_INFO_SECTIONS,
          investmentInfoPageUrls,
          appHubBaseUrl,
        ),
        nextFinancialInfoPageUrl: generateNextUrlFunction(
          FINANCIAL_INFO_SECTIONS,
          financialInfoPageUrls,
          appHubBaseUrl,
        ),
        previousFinancialInfoPageUrl: generatePreviousUrlFunction(
          FINANCIAL_INFO_SECTIONS,
          financialInfoPageUrls,
          appHubBaseUrl,
        ),
        nextSigningPageUrl: (
          currentStep: string,
          application: Api.AccountApplication,
        ) => {
          let sections = SIGNING_SECTIONS;
          let pageUrls = signingPageUrls;
          if (!isENotificationRequired(application)) {
            const sectionIndex = SIGNING_SECTIONS.findIndex(
              (section) => section === "eNotification",
            );
            sections = SIGNING_SECTIONS.filter(
              (section) => section !== "eNotification",
            );
            pageUrls = signingPageUrls.filter(
              (url, index) => sectionIndex !== index,
            );
          }
          return generateNextUrlFunction(
            sections,
            pageUrls,
            appHubBaseUrl,
          )(currentStep);
        },
        previousSigningPageUrl: (
          previousStep: string,
          application: Api.AccountApplication,
        ) => {
          let sections = SIGNING_SECTIONS;
          let pageUrls = signingPageUrls;
          if (!isENotificationRequired(application)) {
            const sectionIndex = SIGNING_SECTIONS.findIndex(
              (section) => section === "eNotification",
            );
            sections = SIGNING_SECTIONS.filter(
              (section) => section !== "eNotification",
            );
            pageUrls = signingPageUrls.filter(
              (url, index) => sectionIndex !== index,
            );
          }
          return generatePreviousUrlFunction(
            sections,
            pageUrls,
            appHubBaseUrl,
          )(previousStep);
        },
        accountOptionsUrls: () => accountOptionsUrls,
        nextAccountOptionsPageUrl: generateNextUrlFunction(
          ACCOUNT_OPTIONS_SECTIONS,
          accountOptionsUrls,
          appHubBaseUrl,
        ),
        previousAccountOptionsPageUrl: generatePreviousUrlFunction(
          ACCOUNT_OPTIONS_SECTIONS,
          accountOptionsUrls,
          appHubBaseUrl,
        ),
      };
    },
  };
}

export interface ResearchUrls {
  baseUrl: () => string;
  funds: () => string;
  history: (fundId?: string | null) => string;
  distributionHistory: (fundId?: string | null) => string;
  performance: () => string;
}
export interface HomeUrl {
  baseUrl: () => string;
}
export function getResearchUrls(): ResearchUrls {
  const baseUrl = "/research";

  return {
    baseUrl: () => baseUrl,
    funds: () => `${baseUrl}/funds`,
    history: (fundId?: string) => `${baseUrl}/fund-history/${fundId ?? ""}`,
    distributionHistory: (fundId?: string) =>
      `${baseUrl}/fund-distribution-history/${fundId ?? ""}`,
    performance: () => `${baseUrl}/fund-performance`,
  };
}

export function getHome(): HomeUrl {
  const baseUrl = "/home";

  return {
    baseUrl: () => baseUrl,
  };
}
export interface ServiceFormUrls {
  baseUrl: () => string;
  mutualfundaccounts: () => string;
  mutualfundaccountdistribution: () => string;
}
export function getServiceFormUrls(): ServiceFormUrls {
  const baseUrl = "/serviceforms";

  return {
    baseUrl: () => baseUrl,
    mutualfundaccounts: () => `${baseUrl}/mutualfundaccounts`,
    mutualfundaccountdistribution: () =>
      `${baseUrl}/mutualfundaccountdistribution`,
  };
}
export function getCurrentPersonalInformationUrl(
  application: Api.AccountApplication,
  accountTypeConfigs: Api.TurboAppAccountTypeConfig[],
): string {
  const lastCompletedStep =
    (application.registrants &&
      application.registrants.findIndex((r) => !r.isComplete)) ||
    0;
  if (lastCompletedStep === -1) {
    const isBenePageAllowed = accountTypeConfigs.some(
      (tc) => tc.id === application.accountTypeCode && tc.beneficiariesAllowed,
    );

    if (!application.secondaryContacts?.isComplete) {
      return getRepToolsUrls()
        .applicationHub(application.id)
        .accountSecondaryContactsPageUrl();
    } else if (
      isBenePageAllowed &&
      !application.beneficiariesInfo?.isComplete
    ) {
      return getRepToolsUrls()
        .applicationHub(application.id)
        .accountBeneficiariesPageUrl();
    } else if (!application.preferences?.isComplete) {
      return getRepToolsUrls()
        .applicationHub(application.id)
        .piPreferencesPageUrl();
    } else {
      return getRepToolsUrls()
        .applicationHub(application.id)
        .accountPersonalInfoPageUrl(0);
    }
  } else {
    return getRepToolsUrls()
      .applicationHub(application.id)
      .accountPersonalInfoPageUrl(lastCompletedStep);
  }
}
