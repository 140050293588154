import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { getServiceFormUrls } from "../../shell/app-url.utils";

import { MaskedValueComponent } from "../../shared/components/masked-value/masked-value.component";
import { getResearchUrls } from "../app-url.utils";
import { AuthService } from "../auth/auth.service";

import { HeaderService, MenuItem } from "./header.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();

  @HostBinding("class.mobile-nav-is-open") mobileNavIsOpen = false;

  accountNumber$: Observable<string | undefined>;
  repNameForShareholder = "";
  repPhoneNumForShareholder = "";
  public activeAccount: Api.Account | null;
  public logOutEnabled = false;
  public isMenuAvailable = true;

  public researchMenuItem: MenuItem = {
    label: "Fund Info",
    url: "/research",
    children: [],
  };
  public serviceFormsMenuItem: MenuItem = {
    label: "Service Forms",
    url: "/serviceForms",
    children: [],
  };

  public activeAccountMenus: MenuItem[];

  plan?: Api.Plan;
  user?: Api.LoggedInUser;
  userType?: Api.UserType;
  isDashboardUrl = false;
  isAccountUrl = false;
  isHomeUrl = false;
  isServiceFormsUrl = false;
  @ViewChild("maskedValue") maskedValueComponent: MaskedValueComponent;
  get masked() {
    return this.maskedValueComponent?.masked ?? true;
  }

  constructor(
    public headerService: HeaderService,
    private authService: AuthService,

    private router: Router,
  ) {}

  ngOnInit(): void {
    this.logOutEnabled = this.authService.supportsLogout();

    this.router.events.pipe(takeUntil(this.destroyed)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.isServiceFormsUrl = ev?.url.startsWith("/serviceforms/");
        this.isHomeUrl = ev?.url === "/home";
        this.isDashboardUrl =
          (!this.user?.capabilities?.includes(
            "shareholder.hide-account-search",
          ) &&
            ev?.url === "/dashboard") ||
          ev?.url === "/profile";
        this.isAccountUrl =
          (this.user?.capabilities?.includes("shareholder.hide-account-search")
            ? ev?.url === "/dashboard"
            : ev?.url === "/search") || ev?.url.startsWith("/accounts/");
      }
    });

    const researchChildren: MenuItem[] = [];
    // if (loggedInUserHasCapabilities(loggedInUser, 'funds.view')) {
    researchChildren.push({
      label: "Fund Prices",
      url: getResearchUrls().funds(),
    });
    researchChildren.push({
      label: "Fund Price History",
      url: getResearchUrls().history(),
    });
    researchChildren.push({
      label: "Distribution History",
      url: getResearchUrls().distributionHistory(),
    });
    researchChildren.push({
      label: "Fund Performance",
      url: getResearchUrls().performance(),
    });
    // }
    // this.homeMenuItem = {
    //   label: 'Home',
    //   url: getHome().baseUrl(),
    // };
    this.researchMenuItem = {
      label: "Fund Info",
      url: getResearchUrls().baseUrl(),
      children: researchChildren,
    };
    const serviceFormsChildren: MenuItem[] = [];
    serviceFormsChildren.push({
      label: "Account Forms",
      url: getServiceFormUrls().mutualfundaccounts(),
    });
    serviceFormsChildren.push({
      label: "Account Distribution Forms",
      url: getServiceFormUrls().mutualfundaccountdistribution(),
    });
    this.serviceFormsMenuItem = {
      label: "Service Forms",
      url: getServiceFormUrls().baseUrl(),
      children: serviceFormsChildren,
    };
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  handleMobileMenuOpening(opening: boolean) {
    this.mobileNavIsOpen = opening;
  }

  logout() {
    // this.logoutProcess = true;
    this.authService.redirectToLogout(document.location.origin);
  }

  get userProfileUrl() {
    if (
      this.activeAccount !== null &&
      this.activeAccount.writingAgent !== null
    ) {
      return `/profile/${this.activeAccount.writingAgent?.id}`;
    }
  }
}
