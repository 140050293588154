import { Location } from "@angular/common";
import { Injector } from "@angular/core";

export const redirectToRootInitializerFactory = (injector: Injector) => () => {
  const location = injector.get(Location);
  return initializeApp(location);
};

const invalidOriginalPath: string[] = ["/error", "/maintenance"];

function initializeApp(location: Location): void {
  if (invalidOriginalPath.includes(location.path(false))) {
    console.warn("Invalid starting URL detected. Will redirect to root");
    location.replaceState("/");
  }
}
