import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { fundFamilyMappings } from "../shared/dynamic-mappers/fund-family.mapping";
import { HttpService } from "../shared/httpService";

@Injectable({
  providedIn: "root",
})
export class AppParametersService implements OnDestroy {
  private destroyed = new Subject<void>();

  private appParameters: Api.AppParameters;

  constructor(private httpService: HttpService) {}

  fillData(): void {
    this.httpService
      .getHttpDataRecord<Api.AppParameters>("/api/appparameters")
      .pipe(takeUntil(this.destroyed))
      .subscribe((p) => {
        this.appParameters = p;
        this.setMappers();
      });
  }

  public getAsOfDate(): Observable<Date | undefined> {
    return of(this.appParameters?.asOfDate ?? undefined);
  }

  public getExternalLink(key: string): string | undefined {
    return this.appParameters?.externalLinks?.[key] ?? undefined;
  }

  public getIndefiniteDate(): Observable<Date> {
    const dt =
      this.appParameters?.indefiniteDate ?? new Date(9999, 12, 31, 23, 59, 59);
    return of(new Date(dt.getTime() - dt.getTimezoneOffset() * 60000));
  }

  private setMappers(): void {
    fundFamilyMappings.setMapper(
      this.appParameters?.nameMappers?.["fundFamily"] ?? {},
    );
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
