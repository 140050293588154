import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
const PDF_PATH = environment.cdnMutualFundAccountPdfPath;
const HTML_PATH = environment.cdnMutualFundAccountHtmlPath;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();

  pdfpath = PDF_PATH;
  htmlpath = HTML_PATH;

  constructor() {}

  ngOnInit() {}
  login() {
    window.open("/login/", "_self");
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
