import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

import { Notification } from "../../shared/notifications/notification";
import { isStandardNotificationOptions } from "../../shared/notifications/notification-options";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
})
export class NotificationComponent implements OnInit {
  @Input() notification: Notification;
  @ViewChild("componentContent", { read: ViewContainerRef, static: true })
  componentContent;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (this.notification.options.contentComponent) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(
        this.notification.options.contentComponent,
      );
      const rendererRef = this.componentContent.createComponent(factory);

      Object.keys(
        (this.notification.options.contentComponentAttributes as {}) ?? [],
      ).forEach((key) => {
        rendererRef.instance[key] = (this.notification.options
          .contentComponentAttributes ?? {})[key];
      });
    }
  }

  get link(): string | any[] | undefined {
    const options = this.notification.options;
    return isStandardNotificationOptions(options) ? options.link : undefined;
  }

  get showDismissButton(): boolean | undefined {
    const options = this.notification.options;
    return isStandardNotificationOptions(options)
      ? options.showDismissButton
      : false;
  }
}
