// These helpers come from Auth0's example of PKCE flow implementation:
// https://github.com/auth0/spa-pkce/blob/master/public/app.js

/**
 * Generates a cryptographically secure random string
 * of variable length.
 *
 * The returned string is also url-safe.
 *
 * @param length the length of the random string.
 */
export function randomString(length = 32) {
  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.charCodeAt(x % validChars.length));
  return String.fromCharCode(...Array.from(array));
}

/**
 * Takes a base64 encoded string and returns a url encoded string
 * by replacing the characters + and / with -, _ respectively,
 * and removing the = (fill) character.
 *
 * @param input base64 encoded string.
 */
export function urlEncodeB64(input: string) {
  const b64Chars = { "+": "-", "/": "_", "=": "" };
  return input.replace(/[\+\/=]/g, (m) => b64Chars[m]);
}

/**
 * Takes an ArrayBuffer and convert it to Base64 url encoded string.
 */
export function bufferToBase64UrlEncoded(input: ArrayBuffer) {
  const bytes = new Uint8Array(input);
  return urlEncodeB64(window.btoa(String.fromCharCode(...Array.from(bytes))));
}

/**
 * Returns the sha256 digst of a given message.
 * This function is async.
 */
export function sha256(message: string): PromiseLike<ArrayBuffer> {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  return window.crypto.subtle.digest("SHA-256", data);
}
