<ng-container *ngIf="(link.children?.length || 0) > 0">
  <ng-container [ngSwitch]="isMobile">
    <button
      *ngSwitchCase="false"
      class="link-button desktop-only"
      disabled
      [routerLink]="link.url"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      {{ link.label }}
    </button>
    <ul *ngSwitchCase="true">
      <li class="mobile-header-link">
        <a
          class="link-url"
          (click)="toggleChildMenu($event)"
          [ngClass]="{
            disabled: link.state === 'disabled',
            hide: link.state === 'hide',
          }"
        >
          {{ link.label }}
          <span
            [ngClass]="
              shouldHideChildMenu ? 'icon-down-caret' : 'icon-up-caret'
            "
          ></span>
        </a>
      </li>
    </ul>
  </ng-container>

  <ul
    [ngClass]="link.childrenContainerClass || ''"
    *ngIf="!(isMobile && shouldHideChildMenu)"
  >
    <li *ngFor="let childLink of link.children">
      <app-header-link [link]="childLink" [isChild]="true"></app-header-link>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="!link.children && link.url">
  <ng-container [ngSwitch]="isMobile && !isChild">
    <!-- the condition of a lone mobile link needs a container to apply styles correctly -->
    <ul *ngSwitchCase="true">
      <li>
        <ng-container *ngTemplateOutlet="containerlessLink"></ng-container>
      </li>
    </ul>

    <ng-container *ngSwitchCase="false">
      <ng-container *ngTemplateOutlet="containerlessLink"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #containerlessLink>
    <a
      class="link-url"
      [routerLink]="link.url"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: link.urlExact || false }"
      [ngClass]="{
        disabled: link.state === 'disabled',
        'child-link': isChild,
        hide: link.state === 'hide',
      }"
    >
      {{ link.label }}
    </a>
  </ng-template>
</ng-container>
