import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  bufferToBase64UrlEncoded,
  randomString,
  sha256,
} from "../../shared/crypto.utils";
import {
  createQuery,
  encodeAsFormUrl,
  removeEmptyValues,
} from "../../shared/route.utils";
import { ConfigService } from "../config.service";

export interface TokenSet {
  access_token: string;
  expires_in: number;
  id_token: string;
  token_type: string;
}

export interface AuthResponse {
  error?: Error;
  state: string;
  code: string;
}

export const REFRESH_STATE_PREFIX = "refresh--";
const FORCE_PROMPT_KEY = "force-prompt";

@Injectable()
export class AuthSsoService {
  redirectUri = `${document.location.origin}/auth/callback`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router,
  ) {}

  private get oidcOptions() {
    const config = this.configService.config;
    return (config.auth as OidcAuthConfig).providerOptions;
  }

  async redirectToLogin() {
    const { state, nonce, codeVerifier, codeChallenge } =
      await this.createAuthChallenge(false);

    sessionStorage.setItem(`login-code-verifier-${state}`, codeVerifier);

    const prompt =
      this.configService.config.auth.logoutStrategy === "forcePrompt" &&
      localStorage.getItem(FORCE_PROMPT_KEY) === "1"
        ? "login"
        : undefined;

    const authorizationEndpointUrl = new URL(this.oidcOptions.urls.loginUrl);
    authorizationEndpointUrl.search = createQuery({
      audience: this.oidcOptions.audienceRequiredAtAcquisition
        ? this.oidcOptions.audience
        : undefined,
      redirect_uri: this.redirectUri,
      client_id: this.oidcOptions.clientId,
      response_type: "code",
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
      scope: this.oidcOptions.scopes.join(" "),
      prompt,
      state,
      nonce,
    });
    window.location.assign(String(authorizationEndpointUrl));
  }

  canLogout(): boolean {
    return this.configService.config.auth.logoutStrategy !== "disabled";
  }

  async redirectToLogout(redirectTo: string) {
    if (!this.canLogout()) {
      console.warn("Logout is not supported by the current OIDC configuration");
      return;
    }

    if (this.configService.config.auth.logoutStrategy === "forcePrompt") {
      localStorage.setItem(FORCE_PROMPT_KEY, "1");
    }

    if (!this.oidcOptions.urls.logoutUrl) {
      console.warn(
        "Using self URL as logout URL since no logout URL provided in configuration",
      );
    }

    const logoutUrl = this.oidcOptions.urls.logoutUrl || window.location.href;

    const logoutEndpointUrl = new URL(logoutUrl);

    if (this.configService.config.auth.logoutStrategy === "redirect") {
      logoutEndpointUrl.search = String(
        new URLSearchParams({
          client_id: this.oidcOptions.clientId,
          returnTo: redirectTo,
        }),
      );
    }
    const to = encodeURIComponent(String(logoutEndpointUrl));

    const redirectUrl = `/redirect?to=${to}`;

    this.router.navigateByUrl(redirectUrl);
  }

  async handleCallback(search: URLSearchParams) {
    if (!search.has("code")) {
      throw new Error("No code in the URL.");
    }

    const code = search.get("code");
    const state = search.get("state");
    const codeVerifier = sessionStorage.getItem(`login-code-verifier-${state}`);

    if (!codeVerifier || !code) {
      console.warn(
        "Unexpected state parameter. Redirecting to SSO login page.",
      );
      await this.redirectToLogin();
      return null;
    }

    sessionStorage.removeItem(`login-code-verifier-${state}`);

    const nextToken = await this.getNewToken(codeVerifier, code);
    if (nextToken && nextToken.access_token) {
      localStorage.removeItem(FORCE_PROMPT_KEY);
    }

    return nextToken;
  }

  async refreshToken() {
    const simulateWebMessages = !this.oidcOptions.supportsWebMessages;

    const { state, nonce, codeVerifier, codeChallenge } =
      await this.createAuthChallenge(simulateWebMessages);

    const authorizationEndpointUrl = new URL(this.oidcOptions.urls.loginUrl);
    authorizationEndpointUrl.search = createQuery({
      audience: this.oidcOptions.audienceRequiredAtAcquisition
        ? this.oidcOptions.audience
        : undefined,
      redirect_uri: this.redirectUri,
      client_id: this.oidcOptions.clientId,
      response_type: "code",
      response_mode: simulateWebMessages ? undefined : "web_message",
      prompt: "none",
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
      scope: this.oidcOptions.scopes.join(" "),
      state,
      nonce,
    });

    const res = await this.http
      .get(authorizationEndpointUrl.toString(), {
        responseType: "text",
        observe: "response",
      })
      .toPromise();
    if (!res || (res.url ?? "") === "")
      throw new Error("No answer for auth token paramaters callback");
    if (!res.ok)
      throw new Error("Invalid answer for auth token paramaters callback");
    const requestParams = new URL(res.url ?? "");
    if (requestParams?.searchParams?.get("state") !== state)
      throw new Error("State does not match.");
    const code = requestParams?.searchParams?.get("code") ?? "";
    if (code === "") throw new Error("Auth Code is not correct.");
    return await this.getNewToken(codeVerifier, code);
  }

  getTokenForApi(tokenSet: TokenSet): string {
    switch (this.oidcOptions.useToken) {
      case "accessToken":
        return tokenSet.access_token;

      case "idToken":
        return tokenSet.id_token;

      default:
        return tokenSet.access_token;
    }
  }

  private async getNewToken(codeVerifier: string, authorizationCode: string) {
    const url = this.oidcOptions.urls.tokenUrl;
    const values = removeEmptyValues({
      audience: this.oidcOptions.audienceRequiredAtAcquisition
        ? this.oidcOptions.audience
        : undefined,
      client_id: this.oidcOptions.clientId,
      redirect_uri: this.redirectUri,
      grant_type: "authorization_code",
      code_verifier: codeVerifier,
      code: authorizationCode,
    });

    const { body, options } =
      this.oidcOptions.codeExchangeFormat === "json"
        ? { body: values, options: undefined }
        : {
            body: encodeAsFormUrl(values),
            options: {
              headers: new HttpHeaders().set(
                "Content-Type",
                "application/x-www-form-urlencoded",
              ),
            },
          };

    return this.http.post<TokenSet>(url, body, { ...options }).toPromise();
  }

  private async createAuthChallenge(isRefresh: boolean) {
    const rawState = randomString();

    const state = isRefresh ? `${REFRESH_STATE_PREFIX}${rawState}` : rawState;
    const nonce = randomString();
    const codeVerifier = randomString(this.oidcOptions.codeVerifierLength);
    const codeChallenge = bufferToBase64UrlEncoded(await sha256(codeVerifier));
    return { state, nonce, codeVerifier, codeChallenge };
  }
}
