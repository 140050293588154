import { Component, OnInit } from "@angular/core";

import {
  APP_DROPPED_THE_BALL_MESSAGE,
  CONTACT_CUSTOMER_SERVICE_MESSAGE,
  combineErrorMessages,
} from "./error.utils";

@Component({
  templateUrl: "./error-page.component.html",
})
export class ErrorPageComponent implements OnInit {
  returnLink = "/";
  forceRefresh = false;
  errorDescription: string | undefined = undefined;

  constructor() {}

  ngOnInit() {
    if (history.state != null) {
      if (history.state.returnLink != null) {
        this.returnLink = history.state.returnLink;
      }

      this.errorDescription =
        history.state.errorDescription ||
        combineErrorMessages(
          APP_DROPPED_THE_BALL_MESSAGE,
          CONTACT_CUSTOMER_SERVICE_MESSAGE,
        );
      this.forceRefresh = !!history.state.forceRefresh;
    }
  }
}
