import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";

import { CellRendererDirective } from "./cell-renderer.directive";

@Component({
  selector: "app-router-link-cell",
  template: `
    <span>
      <a
        *ngIf="!!routerLink && !(disabled | async); else textTemplate"
        [routerLink]="routerLink"
        >{{ displayText ? displayText : rawValue }}</a
      >
      <ng-template #textTemplate>
        {{ displayText ? displayText : rawValue }}
      </ng-template>
    </span>
  `,
})
export class RouterLinkCellRendererComponent extends CellRendererDirective {
  @Input() routerLink: string;
  @Input() disabled?: Observable<boolean> | null;
  @Input() displayText?: string;
  constructor() {
    super();
  }
}
