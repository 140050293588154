import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first, mergeMap } from "rxjs/operators";

import { AuthService } from "../auth/auth.service";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // FIXME we should check that URL matches the API server. The following
    // line won't work the day the API is served from a different host.
    if (!(request.url.startsWith("/api") || request.url.startsWith("api"))) {
      return next.handle(request);
    }
    return this.auth.tokenSet$.pipe(
      first(),
      mergeMap((tokenSet) =>
        tokenSet
          ? next.handle(
              request.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.auth.getTokenForApi(tokenSet)}`,
                },
              }),
            )
          : next.handle(request),
      ),
    );
  }
}
