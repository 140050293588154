import { Injectable } from "@angular/core";

export const defaultConfig: SiteConfig = {
  auth: {
    provider: "fake",
    logoutStrategy: "disabled",
  },
};

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private static validSystemStatusValues: Api.SystemStatusValue[] = [
    "none",
    "normal",
    "readOnly",
  ];

  private systemStatusOverride: Api.SystemStatusValue | undefined;

  constructor() {}

  get config(): SiteConfig {
    const backendConfig = (window as any).__siteConfig__;
    if (backendConfig) {
      return backendConfig as SiteConfig;
    }
    return defaultConfig;
  }

  get systemStatus(): Api.SystemStatusValue | undefined {
    return this.systemStatusOverride;
  }

  set systemStatus(next: Api.SystemStatusValue | undefined) {
    if (
      next !== undefined &&
      !ConfigService.validSystemStatusValues.includes(next)
    ) {
      const validValues = ConfigService.validSystemStatusValues.join(", ");
      console.warn(
        `Invalid system status passed. Please provide a valid value (undefined, ${validValues}).`,
        next,
      );
      return;
    }

    this.systemStatusOverride = next;
  }
}
