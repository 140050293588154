export const environment = {
  production: true,
  hmr: false,
  allowFakeAuth: false,
  warn: false,
  timeBetweenInstallPrompts: 604800000, // 1 week
  enforceMaintenanceMode: true,
  userIdleTimeoutSeconds: 1200, // 20 minutes
  cdnMutualFundAccountFormsPath: "/dflt/js/mutualfundaccountforms.js",
  cdnMutualFundAccountDistributionFormsPath:
    "/dflt/js/mutualfundaccountdistributionforms.js",
  cdnMutualFundAccountPdfPath: "/dflt/pdf",
  cdnMutualFundAccountHtmlPath: "/dflt/html",
};
