import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-browsercompatibility",
  templateUrl: "./browsercompatibility.component.html",
  styleUrls: ["./browsercompatibility.component.scss"],
})
export class BrowserCompatibilityComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();

  browserName = "";
  browserVersion = "";
  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edg") > -1:
        return "edge";
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(window as any).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(window as any).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }
  detectBrowserVersion() {
    const userAgent = navigator.userAgent;
    let tem;
    let matchTest =
      userAgent.match(
        /(opera|edg|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return "IE " + (tem[1] || "");
    }
    if (matchTest[1] === "Chrome") {
      tem = userAgent.match(/\b(OPR)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
      tem = userAgent.match(/\b(Edge|Edg)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("Edg", "Edge");
    }
    matchTest = matchTest[2]
      ? [matchTest[1], matchTest[2]]
      : [navigator.appName, navigator.appVersion, "-?"];
    tem = userAgent.match(/version\/(\d+)/i);
    if (tem != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(" ");
  }

  constructor() {}

  ngOnInit() {
    this.browserName = this.detectBrowserName();
    this.browserVersion = this.detectBrowserVersion();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
