import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

import { AccountingPipe } from "./accounting";

@Pipe({ name: "accountingShares", pure: true })
export class AccountingSharesPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private accountingPipe: AccountingPipe,
  ) {}

  transform(value: any, ...args: any[]): any {
    if (value == null) {
      value = 0;
    }

    return this.accountingPipe.transform(
      this.decimalPipe.transform(value, "1.3-3"),
    );
  }
}
