import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ACCESSPORTALS,
  BNYMELLON,
} from "../../shell/http-interceptors/white-list-urls";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PayloadInterceptor implements HttpInterceptor {
  host: string = window.location.origin;
  environmentUrl: string;
  constructor(private router: Router) {}

  intercept<T>(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const url = window.location.href;
    if (url.includes("/redirect") && url.includes("to=")) {
      url.split("?").forEach((param) => {
        const [key, value] = param.split("=");
        if (key === "to") {
          if (!value.includes(BNYMELLON) && !value.includes(ACCESSPORTALS)) {
            this.router.navigate(["/error"]);
          }
        }
      });
    }
    return next.handle(request);
  }
}
