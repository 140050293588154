import { Component, Input } from "@angular/core";

import { CellRendererDirective } from "./cell-renderer.directive";

@Component({
  selector: "app-text-cell-renderer",
  template: '<span [class]="class">{{rawValue}}</span>',
})
export class TextCellRendererComponent extends CellRendererDirective {
  @Input() class? = "";
}
