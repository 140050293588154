import { Injectable, Injector } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from "rxjs/operators";

import { concatUrl } from "../../../shared/route.utils";

import {
  BackButtonResolver,
  DefaultBackButtonResolver,
} from "./back-button.resolver";

export interface BackButtonInfo {
  label: string;
  url: string;
  state?: object;
  isRelativeUrl?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class BackButtonService {
  private buttonInfo$ = new BehaviorSubject<BackButtonInfo | null>(null);
  private defaultResolver = new DefaultBackButtonResolver();
  private prevRouteSnapshot: RouterStateSnapshot;

  constructor(
    private router: Router,
    private injector: Injector,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => this.router.routerState.snapshot.root),
        map((route) => {
          let backButtonConfig = this.getRouteBackButton(route);

          while (route.firstChild) {
            route = route.firstChild;
            backButtonConfig =
              this.getRouteBackButton(route) || backButtonConfig;
          }

          return { route, backButtonConfig };
        }),
        distinctUntilChanged(),
        switchMap((c) =>
          this.resolveBackButtonInfo(c.route, c.backButtonConfig),
        ),
      )
      .subscribe((x) => {
        this.buttonInfo$.next(x);
      });
  }

  get backButtonInfo$(): Observable<BackButtonInfo | null> {
    return this.buttonInfo$.pipe(
      map((backButtonInfo) => {
        if (backButtonInfo) {
          if (backButtonInfo.isRelativeUrl) {
            return {
              ...backButtonInfo,
              url: concatUrl(
                this.router.routerState.snapshot.url,
                backButtonInfo.url,
              ),
            };
          }
        }
        return backButtonInfo;
      }),
    );
  }

  getRouteBackButton(route: ActivatedRouteSnapshot): any {
    return (
      route.routeConfig &&
      route.routeConfig.data &&
      route.routeConfig.data.backButton
    );
  }

  resolveBackButtonInfo(
    routeSnapshot: ActivatedRouteSnapshot,
    backButtonConfig: any,
  ): Observable<BackButtonInfo | null> {
    if (backButtonConfig) {
      let resolver: BackButtonResolver;

      if (backButtonConfig.prototype instanceof DefaultBackButtonResolver) {
        resolver = this.injector.get<BackButtonResolver>(backButtonConfig);
      } else {
        resolver = this.defaultResolver;
      }
      const result = resolver.resolve(routeSnapshot, this.prevRouteSnapshot);
      this.prevRouteSnapshot = this.router.routerState.snapshot;
      return result.pipe(first());
    }
    this.prevRouteSnapshot = this.router.routerState.snapshot;
    return of(null);
  }
}
