import { Injectable, NgZone } from "@angular/core";

export interface Scheduler {
  cancel: () => void;
  schedule: (ms: number) => void;
}

@Injectable({
  providedIn: "root",
})
export class TimeoutSchedulerService {
  constructor(private zone: NgZone) {}

  createScheduler(
    fn: () => void,
    shouldSchedulingRunOutsideAngular: boolean = false,
  ): Scheduler {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    const cancel = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }
    };
    const schedule = (ms: number) => {
      cancel();
      if (shouldSchedulingRunOutsideAngular) {
        const callback = () => this.zone.run(fn);
        const startTimeout = () => {
          timeoutId = setTimeout(callback, ms);
        };
        this.zone.runOutsideAngular(startTimeout);
      } else {
        timeoutId = setTimeout(fn, ms);
      }
    };
    return { cancel, schedule };
  }
}
