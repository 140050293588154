import { AfterViewInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  template: "",
})
export class RedirectPageComponent implements AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    const url = this.route.snapshot.queryParams.to || "error";
    if (url.includes("://")) {
      // Redirect to external site
      window.location.assign(url);
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
