import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

import { AccountingPipe } from "./accounting";

@Pipe({ name: "accountingCurrency", pure: true })
export class AccountingCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private accountingPipe: AccountingPipe,
  ) {}

  transform(value: any, ...args: any[]): any {
    if (value == null) {
      value = 0;
    }

    return this.accountingPipe.transform(
      this.currencyPipe.transform(value, "USD"),
    );
  }
}
