import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { IfCapabilitiesDirective } from "./if-capabilities.directive";

@NgModule({
  declarations: [IfCapabilitiesDirective],
  imports: [CommonModule],
  exports: [IfCapabilitiesDirective],
})
export class LoggedInUserModule {}
