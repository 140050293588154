import { Location } from "@angular/common";
import { Injector } from "@angular/core";
import { Router } from "@angular/router";

import { SERVER_OUT_OR_OFFLINE_MESSAGE } from "./shell/error/error.utils";

export const appOnlineInitializerFactory = (injector: Injector) => () => {
  const router = injector.get(Router);
  const location = injector.get(Location);
  return initializeApp(router, location);
};

async function initializeApp(
  router: Router,
  location: Location,
): Promise<void> {
  try {
    const healthCheckRes = await fetch("/api/health");
    if (!healthCheckRes.ok) {
      throw new Error("Health check did not pass with a 200 OK");
    }
  } catch (err) {
    console.warn("Application is considered offline", err);

    const returnLink = location.path(true);

    await router.navigate(["/error"], {
      state: {
        returnLink,
        forceRefresh: true,
        errorDescription: SERVER_OUT_OR_OFFLINE_MESSAGE,
      },
    });
  }
}
