import { Location } from "@angular/common";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "../auth/auth.service";
import {
  CONTACT_CUSTOMER_SERVICE_MESSAGE,
  combineErrorMessages,
} from "../error/error.utils";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            console.warn("User is unauthorized [401 (Unauthorized)]");
            const relocationUrl = err.headers.get("location");

            if (err.url?.endsWith("/api/status")) {
              // this is status request, if authorization token is expired, redirect to logout
              console.warn(
                "Status request unauthorized, redirecting to logout page",
              );
              this.authService.redirectToLogout(document.location.origin);
            } else if (relocationUrl) {
              console.warn("Redirecting to SSO login page with relocation URL");
              const queryStrIndex = relocationUrl.indexOf("?");
              const redirectUrl =
                queryStrIndex === -1
                  ? relocationUrl
                  : relocationUrl.substr(0, queryStrIndex);

              const redirectTo = getRedirectToQuery();

              window.location.href = `${redirectUrl}?redirectUrl=${redirectTo}`;
            } else {
              console.warn("Redirecting to error page");
              const returnLink = this.location.path(true);

              this.router.navigate(["/error"], {
                state: {
                  returnLink,
                  errorDescription: combineErrorMessages(
                    "We are unable to get you authenticated",
                    CONTACT_CUSTOMER_SERVICE_MESSAGE,
                  ),
                  forceRefresh: true,
                },
              });
            }
          }
        }

        return throwError(err);
      }),
    );
  }
}

function getRedirectToQuery() {
  const { pathname, search } = window.location;
  const path = `${pathname}${search}`;

  return encodeURIComponent(path);
}
