import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  JsonPipe,
  SlicePipe,
  TitleCasePipe,
} from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { LOADING_BAR_CONFIG, LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import {
  StorageService,
  SumdayCommonModule,
  WindowRefService,
} from "@sumday/common";
import { SumdayComponentModule } from "@sumday/components";

import { environment } from "../environments/environment";

import { appOnlineInitializerFactory } from "./app-online.initializer";
import { AppRoutingModule } from "./app-routing.module";
import { appToolingInitializerFactory } from "./app-tooling.initializer";
import { AppComponent } from "./app.component";
import { redirectToRootInitializerFactory } from "./redirect-to-root.initializer";
import { AccountingPipe } from "./shared/pipes/accounting";
import { AccountingCurrencyPipe } from "./shared/pipes/accounting-currency";
import { AccountingSharesPipe } from "./shared/pipes/accounting-shares";
import { SharedModule } from "./shared/shared.module";
import { ShellModule } from "./shell/shell.module";
import { PayloadInterceptor } from "./shell/http-interceptors/payload.interceptor";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SumdayCommonModule.forRoot(),
    SumdayComponentModule,
    ShellModule.forRoot(),
    AppRoutingModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    BrowserAnimationsModule,
    SharedModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appToolingInitializerFactory,
      deps: [Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appOnlineInitializerFactory,
      deps: [Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: redirectToRootInitializerFactory,
      deps: [Injector],
      multi: true,
    },
    {
      provide: LOADING_BAR_CONFIG,
      useValue: {
        latencyThreshold: 100,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PayloadInterceptor,
      multi: true,
    },
    CurrencyPipe,
    JsonPipe,
    DecimalPipe,
    DatePipe,
    TitleCasePipe,
    AccountingPipe,
    AccountingCurrencyPipe,
    AccountingSharesPipe,
    SlicePipe,
    WindowRefService,
    StorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
