import { Location } from "@angular/common";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { APP_DROPPED_THE_BALL_MESSAGE } from "../error/error.utils";

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private location: Location,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status >= 500 && err.status <= 599) {
            console.warn("5XX Server error");

            // skip SuRPAS unavailable exceptions
            if (err.error?.metadata?.status?.status === "none") {
              console.warn("SuRPAS is unavailable");
              return of<never>();
            }

            const returnLink = this.location.path(true);
            if (err.status === 504) {
              // Back-end is not available
              this.router.navigateByUrl("/maintenance");
            } else {
              this.router.navigate(["/error"], {
                state: {
                  returnLink,
                  errorDescription: APP_DROPPED_THE_BALL_MESSAGE,
                  forceRefresh: true,
                },
              });
            }
          }
        }

        return throwError(err);
      }),
    );
  }
}
