import { Injectable } from "@angular/core";
// import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleTagManager } from "angulartics2";

// import { PlanService } from '../plan.service';

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  plan: Api.Plan | undefined;

  constructor(
    // private planService: PlanService,
    // private angulartics: Angulartics2,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ) {}

  startTracking() {
    this.angulartics2GoogleTagManager.startTracking();

    // this.planService.plan$.subscribe((newPlan) => {
    //   if ((newPlan && newPlan.id) === (this.plan && this.plan.id)) {
    //     return;
    //   }

    //   this.plan = newPlan;

    //   let dataLayer = {};
    //   if (this.plan) {
    //     dataLayer = {
    //       planid: this.plan.id,
    //       accountType: this.plan.type,
    //     };
    //   } else {
    //     dataLayer = {
    //       planid: undefined,
    //       accountType: undefined,
    //     };
    //   }

    //   this.angulartics.eventTrack.next({
    //     action: '',
    //     properties: {
    //       gtmCustom: dataLayer,
    //     },
    //   });
    // });
  }
}
