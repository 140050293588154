import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

let COUNTER = 0;

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  currentLoaders: { [loaderId: number]: boolean } = {};

  startLoader(): number {
    const loaderId = COUNTER++;
    this.currentLoaders[loaderId] = true;
    this.isLoading$.next(true);
    return loaderId;
  }

  clearLoader(loaderId: number) {
    delete this.currentLoaders[loaderId];
    if (Object.keys(this.currentLoaders).length === 0) {
      this.isLoading$.next(false);
    }
  }
}
