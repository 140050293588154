<app-header></app-header>
<ngx-loading-bar [includeSpinner]="false" [color]="'#b22222'"></ngx-loading-bar>
<app-notifications [attr.data-qa]="'notification-tray'"></app-notifications>
<div *ngIf="apiAccessToken" style="display: none" id="api-access-token">
  {{ apiAccessToken }}
</div>

<main class="page-container">
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>

<div class="business-continuity-details">
  <span><div [innerHTML]="businesscontinuitymsg"></div></span>
</div>

<app-takeover-loader></app-takeover-loader>
<sumday-takeover-modal></sumday-takeover-modal>
