import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { DialogService } from "@sumday/common";

import { TimeoutSchedulerService } from "../../shared/timeout-scheduler.service";
import { ConfigService } from "../config.service";

import { AuthSsoService } from "./auth-sso.service";
import { AuthService } from "./auth.service";
import { FakeAuthService } from "./fake-auth.service";
import { PkceAuthService } from "./pkce-auth.service";

const authServiceFactory = (
  configService: ConfigService,
  injector: Injector,
) => {
  const authConfig = configService.config.auth;
  switch (authConfig.provider) {
    case "oidc":
      return new PkceAuthService(
        new AuthSsoService(
          injector.get(HttpClient),
          configService,
          injector.get(Router),
        ),
        injector.get(Router),
        injector.get(TimeoutSchedulerService),
        injector.get(DialogService),
      );
    case "fake":
      return new FakeAuthService();
  }
};

export const authServiceProvider = {
  provide: AuthService,
  useFactory: authServiceFactory,
  deps: [ConfigService, Injector],
};
