import { AfterViewInit, Directive, ElementRef } from "@angular/core";

import { ConfigService } from "../config.service";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "img",
})
export class CdnImageDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private configService: ConfigService,
  ) {}

  static transformCdnUrl(src: string, cdnHost: string): string {
    let transformedUrl = src;

    const isAbsolutePath = /https?:\/\//i;
    if (isAbsolutePath.test(src)) {
      const url = new URL(src);
      transformedUrl = url.pathname;
    }

    transformedUrl = transformedUrl.replace(/^\/?/, "/");

    cdnHost = cdnHost.replace(/\/?$/, "");
    transformedUrl = cdnHost + transformedUrl;

    return transformedUrl;
  }

  ngAfterViewInit(): void {
    const cdnHost = this.configService.config.cdnHost;
    if (!cdnHost) {
      return;
    }

    const newSrc = CdnImageDirective.transformCdnUrl(
      this.el.nativeElement.src,
      cdnHost,
    );
    this.el.nativeElement.src = newSrc;
  }
}
