import { Injectable } from "@angular/core";
import { of } from "rxjs";

import { TokenSet } from "./auth-sso.service";
import { AuthService } from "./auth.service";

@Injectable()
export class FakeAuthService extends AuthService {
  /**
   * Open the login page of the SSO.
   */
  async login(redirectTo: string) {}

  supportsLogout() {
    return false;
  }

  async redirectToLogout(redirectTo: string) {}

  /**
   * Get the authorization code from the URL params, then fetch the access
   * token from the SSO and schedule its refresh.
   */
  async handleCallback() {}

  /**
   * Ask the SSO for a new token, then schedule a token refresh. If the SSO
   * returns an error, remove the token from local storage so the user is
   * considered logged out on our side too.
   */
  refreshToken(): Promise<boolean> {
    return Promise.resolve(true);
  }

  get tokenSet$() {
    const fakeTokenSet: TokenSet = {
      access_token: "fake_access_token",
      expires_in: Number.POSITIVE_INFINITY,
      id_token: "fake_id_token",
      token_type: "Bearer",
    };
    return of(fakeTokenSet);
  }

  getTokenForApi(tokenSet: TokenSet): string {
    return tokenSet.access_token;
  }

  promptForTokenRefresh(): Promise<void> {
    console.warn("Token refresh is not supported");

    return Promise.resolve();
  }

  setUserIdleTimeout(seconds: number | undefined): void {}
}
