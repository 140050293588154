import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";

import { BackButtonInfo } from "./back-button.service";

export interface BackButtonResolver {
  resolve(
    route: ActivatedRouteSnapshot,
    prevRouteState: RouterStateSnapshot,
  ): Observable<BackButtonInfo | null>;
}

export class DefaultBackButtonResolver implements BackButtonResolver {
  resolve(
    route: ActivatedRouteSnapshot,
    prevRouteState: RouterStateSnapshot,
  ): Observable<BackButtonInfo | null> {
    const backButtonData = route.data && route.data.backButton;

    if (backButtonData) {
      return of(backButtonData);
    }
    return of(null);
  }

  constructor() {}
}
