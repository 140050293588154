<a
  *ngIf="backButtonInfo$ | async as backButtonInfo"
  [routerLink]="backButtonInfo?.url"
  [state]="backButtonInfo?.state"
  class="steps-back-link btn-back"
  role="button"
  tabindex="0"
>
  <span class="icon-left-caret"></span> {{ backButtonInfo?.label }}
</a>
