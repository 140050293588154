export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const phoneNumberFormatter = (value) => {
  if (!value) return value;
  const numArray =
    value.split("").filter((val) => val !== "-" && val !== " ") ?? [];
  if (numArray[0] === "1") {
    numArray.splice(0, 1);
  }
  const phone = numArray.join("");
  return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ?? "";
};
