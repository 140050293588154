<app-page>
  <div class="section-title">
    <div class="title-text">
      <h1>
        Your browser version is {{ browserVersion }}. To make the best use of
        the site, please ensure you're using one of the latest browser versions.
      </h1>
    </div>
  </div>
  <div>
    <h2>Desktop</h2>

    <h3>Mac | Mac OS X 10.15 (Catalina) and above</h3>
    <li>
      <a target="_blank" rel="noopener" href="https://www.google.com/chrome/">
        Chrome 99 </a
      >and above
    </li>
    <li>
      <a target="_blank" rel="noopener" href="https://www.apple.com/safari/"
        >Safari 15 </a
      >and above
    </li>
    <li>
      <a
        target="_blank"
        rel="noopener"
        href="https://www.mozilla.org/firefox/download/"
      >
        Firefox 101 </a
      >and above
    </li>
    <br />
    <h3>PC | Windows 10 and above</h3>
    <li>
      <a
        target="_blank"
        rel="noopener"
        href="https://www.microsoft.com/en-us/edge"
      >
        Microsoft Edge 103
      </a>
      and above
    </li>
    <li>
      <a target="_blank" rel="noopener" href="https://www.google.com/chrome/">
        Chrome 99 </a
      >and above
    </li>
    <br />
    <h2>Tablet</h2>

    <h3>iPad | iOS 15 and above</h3>
    <li>Safari 15 and above</li>
    <br />
    <h2>Mobile</h2>
    <h3>iPhone | iOS 15 and above</h3>
    <li>Safari 15 and above</li>
    <li>Chrome 99 and above</li>
    <br />
    <h3>Android 10.0 and above</h3>
    <li>Chrome 99 and above</li>
  </div>
</app-page>
