export type NotificationType = "notification" | "confirmation";

interface BaseNotificationOptions {
  notificationType?: NotificationType;
  content?: string;
  contentComponent?: any;
  contentComponentAttributes?: Record<string, any>;
  icon?: string;
  forPage?: boolean;
  type?: "promo" | "promo-gift" | "cta" | "maintenance" | "error" | "success";
  onDismiss?: () => void;
  qaId?: string;
}

export interface ConfirmationOptions extends BaseNotificationOptions {
  notificationType: "confirmation";
  confirmText: string;
  onConfirm: () => void;
  cancelText: string;
  onCancel?: () => void;
}

export interface StandardNotificationOptions extends BaseNotificationOptions {
  notificationType?: "notification";
  link?: string | any[];
  showDismissButton?: boolean;
}

export type NotificationOptions =
  | ConfirmationOptions
  | StandardNotificationOptions;

export const isStandardNotificationOptions = (
  options: NotificationOptions,
): options is StandardNotificationOptions => {
  return (
    options.notificationType === "notification" ||
    options.notificationType == null
  );
};
