import { Component, Input, OnChanges, OnInit } from "@angular/core";

import { Transform } from "../../../transforms/transform";

import { CellRendererDirective } from "./cell-renderer.directive";

@Component({
  selector: "app-transform-cell-renderer",
  template: `
    <span
      [class]="(rawValue == null ? nilValueClass : '') + ' ' + textValueClass"
      >{{ value }}</span
    >
  `,
})
export class TransformCellRendererComponent
  extends CellRendererDirective
  implements OnInit, OnChanges
{
  public value: string | null;

  @Input() textValueClass = "";
  @Input() nilValueClass = "";
  @Input() transform: Transform = () => "";

  ngOnInit(): void {
    this.updateValue();
  }

  ngOnChanges() {
    this.updateValue();
  }

  private updateValue() {
    this.value = this.transform(this.rawValue);
  }
}
